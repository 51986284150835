<template>
    <div class="preview_area show_sidebar" v-if="modelValue" id="workout-wizard">
        <div class="global_setting">
            <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <Form @submit="handleSubmitForm" class="workout-wizard-form" v-slot="{ errors }" ref="workout-wizard-form">
                    <div class="step_wpr content_area" @click="closeAllDropdown()">
                        <div class="info_bar">
                            <div class="time">Estimated Time: <span>6 min.</span></div>
                            <div class="step_bar2">
                                <ul>
                                    <li @click="toggleStep(2)" :class="{ 'active' : workoutStep == 2 || workoutStep > 2}">
                                        <span>1</span>
                                        <h6>Workout</h6>
                                    </li>
                                    <li @click="toggleStep(3)" :class="{ 'active' : workoutStep == 3 || workoutStep > 3}">
                                        <span>2</span>
                                        <h6>Public Page</h6>
                                    </li>
                                    <li @click="toggleStep(4);" :class="{ 'active' : workoutStep == 4 || workoutStep > 4}">
                                        <span>3</span>
                                        <h6>Notifications</h6>
                                    </li>
                                    <li @click="toggleStep(5)" :class="{ 'active' : workoutStep == 5 || workoutStep > 5 }">
                                        <span>4</span>
                                        <h6>SEO</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 3) * (workoutStep - 2) }%`"></span></div>
                            </div>
                            <button type="button" class="tutorial_btn" @click="handleTutorialVideo()">Tutorial</button>
                        </div>
                        <ul class="btn_list" v-if="workoutStep == 3">
                            <li v-if="toggleAll" @click="openAllSection()">Open All</li>
                            <li v-else @click="closeAllSection()">Close All</li>
                        </ul>
                        <div class="content_wpr" v-if="workoutStep == 2">
                            <div class="section_content w-100 open_area">
                                <Form @invalid-submit="handleFormInvalidSubmit" @submit="handleSubmitForm" ref="rename-workout-form">
                                    <div class="workout_info" v-click-outside="closeDescription">
                                        <div class="title_bar">
                                            <div class="inline-edit">
                                                <h3 class="sub_header" :class="{ 'has-error': errors.name }" v-if="!editWorkoutName" @click="handleEditWorkoutName()">
                                                    {{ form.name }}
                                                    <span role="alert" v-if="errors.name" class="text-danger">{{ errors.name }}</span>
                                                </h3>
                                                <div class="rename_fld" v-if="editWorkoutName">
                                                    <Field autocomplete="off" id="workout-name" type="text" name="name" v-model="form.name" placeholder="Workout Name" rules="required" />
                                                    <button type="button" class="save_btn" @click="handleRenameWorkoutName()">
                                                        <i class="fa fa-spin fa-spinner" v-if="renameWorkoutLoader"></i> {{ renameWorkoutLoader ? 'Saving' : 'Save' }}
                                                    </button>
                                                    <ErrorMessage name="name" class="text-danger mb-2 ml-3" />
                                                </div>
                                                <button type="button" class="setting_btn" @click="showDescription = !showDescription;" v-tooltip="'Workout settings'" position="left"><i class="fas fa-cog"></i></button>
                                            </div>
                                        </div>
                                        <div class="setting_wpr description" :class="{'show' : showDescription}" v-if="showDescription">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Workout Categories <a class="option" @click="createCategory = !createCategory">Create Category</a></label>
                                                        <div class="field_wpr">
                                                        <multiselect
                                                            v-model="form.categories"
                                                            :options="workoutCategories"
                                                            :searchable="true"
                                                            valueProp="id"
                                                            label="name"
                                                            placeholder="Select Category"
                                                            mode="tags"
                                                            @select="handleAutoSaveForm()"
                                                            @deselect="handleAutoSaveForm()"
                                                        ></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="createCategory" class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Category Name</label>
                                                    <Form @submit="handleCreateCategory" v-slot="{ errors }" ref="category-form">
                                                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                                            <Field autocomplete="off" type="text" name="name" v-model="categoryForm.name" placeholder="Enter Category Name" rules="required" />
                                                            <span class="suffix" @click="handleCreateCategory()"><i class="fa fa-spin fa-spinner" v-if="categorySaveLoader"></i> {{ categorySaveLoader ? 'Creating' : 'Create' }}</span>
                                                        </div>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </Form>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Description</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.description }">
                                                        <Field name="description" v-model="form.description" label="description" autocomplete="off" rules="required" >
                                                            <textarea name="description" autocomplete="off" @focusout="handleAutoSaveForm()" cols="30" rows="10" placeholder="Description goes here.." v-model="form.description"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="description" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="capsule_elm pt-1 pb-3">
                                                <h5 class="large">Make Private?<span>Turn On / Off to make it Private or public.</span></h5>
                                                <label for="make_private" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="make_private" :true-value="1" :false-value="0" v-model="form.is_private" @change="handleAutoSaveForm();" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div class="addblock_section">
                                <button v-if="!createBlockLoader" type="button" class="add_btn pointer" @click="addNewBlock = !addNewBlock;" v-click-outside="closeBlockTypes"><i class="fas fa-plus"></i>Add Block</button>
                                <button v-else type="button" class="add_btn pointer"><i class="fa fa-spin fa-spinner"></i> Adding Block</button>
                                <div class="add_types" v-if="addNewBlock">
                                    <div class="title">Select Block Type</div>
                                    <ul>
                                        <li v-for="(block, key) in blockTypes"  :key="key" @click="handleCreateWorkoutBlock(block)" >
                                            <div class="card_item">
                                                <img src="@/assets/images/template.svg" :alt="block">{{ block }}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="formsetting">
                                <draggable v-model="blockList" tag="ul" item-key="id" :animation="200" class="block_list" @end="handleSortWorkoutBlocks()" handle=".handle-cat-drag">
                                    <template #item="{ element, index }">
                                        <li @click="selectBlockIndex = index; selectedWorkoutBlock = element" :class="{'circuit' : element.perform_type == 2}">
                                            <div class="section_header">
                                                <div class="handle-cat-drag">
                                                    <i class="fas fa-arrows-alt handle-cat-drag"></i>
                                                    <h3 class="sub_header m-0" v-if="!editBlockName || element.id != selectedWorkoutBlock.id" @click="editBlockName = true;">{{ element.name }}</h3>
                                                    <input type="text" v-model="element.name" v-if="editBlockName && element.id == selectedWorkoutBlock.id">
                                                    <span class="save_btn" v-if="editBlockName && element.id == selectedWorkoutBlock.id" @click="handleRenameWorkoutBlockName()">Save</span>
                                                </div>
                                                <a v-if="element.perform_type == 2" class="cat_info">
                                                    {{element.circuit_settings.amrap ? 'AMRAP' : `${element.circuit_settings.round} rounds`}}
                                                    <div class="quick_info" v-if="element.circuit_settings.amrap">As Many Round As Possible</div>
                                                    <label v-if="blockHasWorkTime(element)">| {{ filterWorkTime(element) }} work </label>
                                                    <label v-if="element.circuit_settings.rest_min || element.circuit_settings.rest_sec">| {{ filterRestTime(element) }}</label>
                                                    <label v-if="element.circuit_settings.amrap && element.circuit_settings.has_duration && (element.circuit_settings.block_duration_min || element.circuit_settings.block_duration_sec)"> | {{ element.circuit_settings.block_duration_min ? `${element.circuit_settings.block_duration_min} min` : ''  }} {{ element.circuit_settings.block_duration_sec ? `${element.circuit_settings.block_duration_sec} sec` : ''  }}  duration</label>
                                                </a>
                                                <div class="block_actions">
                                                    <label class="info" v-tooltip="blockTypes[element.block_type - 1] ? blockTypes[element.block_type - 1] : 'Unidentified'" position="top">
                                                        <i class="fas fa-info"></i>
                                                    </label>
                                                    <div type="button" class="block_setting" v-if="element.name !== 'Rest Period'" v-click-outside="handleBlockSettingOutsideClick">
                                                        <span @click="toggleBlockSetting(element.id)" v-tooltip="'Block settings'" position="top"><i class="fas fa-cog"></i></span>
                                                        <div class="dropdown_wpr active" v-if="element.id == lastWorkoutBlockId">
                                                            <ul class="setting_wpr" v-if="element.perform_type == 2">
                                                                <li>
                                                                    Rounds
                                                                    <div class="form_grp py-1 mt-1" v-if="!element.circuit_settings.amrap">
                                                                        <div class="group_item">
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.circuit_settings.round" @change="handleUpdateWorkoutBlock()">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li v-if="element.circuit_settings.amrap">
                                                                    <div class="capsule_elm mb-1">
                                                                        <h5>Duration</h5>
                                                                        <label for="has_duration" class="switch_option capsule_btn p-0">
                                                                            <input type="checkbox" id="has_duration" :true-value="1" :false-value="0" v-model="element.circuit_settings.has_duration" @change="handleUpdateWorkoutBlock()" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="form_grp py-1" v-if="element.circuit_settings.has_duration">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Minutes</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.circuit_settings.block_duration_min" @change="handleUpdateWorkoutBlock()">
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Seconds</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" max="60" v-model="element.circuit_settings.block_duration_sec" @change="handleUpdateWorkoutBlock()">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <div class="capsule_elm">
                                                                <h5 class="sec_label mb-0">Save as Smart Block</h5>
                                                                <label :for="`smart_block-${index}`" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" :id="`smart_block-${index}`" :true-value="1" :false-value="0" v-model="element.is_smart_block" @change="!element.is_smart_block || element.smart_block_id ? handleUpdateWorkoutBlock() : ''" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div class="form_grp py-1 m-0" v-if="element.is_smart_block == 1">
                                                                <div class="group_item">
                                                                    <label class="input_label">Name</label>
                                                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.smart_block_name }">
                                                                        <Field autocomplete="off" type="text" name="smart_block_name" v-model="element.smart_block_name" placeholder="Smart Block" rules="required" label="Smart block name" />
                                                                        <button :disabled="smartBlockLoader" type="button" class="suffix smart-button" @click="handleUpdateSmartBlock()"><i class="fa fa-spin fa-spinner" v-if="smartBlockLoader"></i>{{ smartBlockLoader ? ' Saving' : 'Save' }}</button>
                                                                    </div>
                                                                    <ErrorMessage name="smart_block_name" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="capsule_elm mt-2">
                                                                <h5 class="sec_label mb-0">Block Screen</h5>
                                                                <label :for="`has_block_int-${index}`" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" :id="`has_block_int-${index}`" :true-value="1" :false-value="0" v-model="element.has_block_screen" @change="handleUpdateWorkoutBlock()" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <ul class="setting_wpr">
                                                                <li>
                                                                    <label class="sec_label pt-0 pb-3">Block Interval</label>
                                                                    <div class="form_grp pt-0 pb-3">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Minutes</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.block_interval_min" @change="handleUpdateWorkoutBlock()">
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Seconds</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" max="60" v-model="element.block_interval_sec" @change="handleUpdateWorkoutBlock()">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Manually Start Timer</h5>
                                                                        <label for="block_int_manual" class="switch_option capsule_btn p-0">
                                                                            <input type="radio" id="block_int_manual" :value="1" v-model="element.block_interval_type" @change="handleUpdateWorkoutBlock()" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="capsule_elm mb-2">
                                                                        <h5>Auto Start Timer</h5>
                                                                        <label for="block_int_auto" class="switch_option capsule_btn p-0">
                                                                            <input type="radio" id="block_int_auto" :value="2" v-model="element.block_interval_type" @change="handleUpdateWorkoutBlock()" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <label :for="`has_work_int-${index}`" class="switch_option capsule_btn p-0 mt-2 mb-0 border-0">
                                                                <h5 class="sec_label mb-0">Work Interval</h5>
                                                                <input type="checkbox" :id="`has_work_int-${index}`" :true-value="1" :false-value="0" v-model="element.has_work_interval" @change="handleUpdateWorkoutBlock()" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <ul class="setting_wpr">
                                                                <li>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Manually Start Timer</h5>
                                                                        <label for="work_int_manual" class="switch_option capsule_btn p-0">
                                                                            <input type="radio" id="work_int_manual" :value="1" v-model="element.work_interval_type" @change="handleUpdateWorkoutBlock()" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="capsule_elm mb-2">
                                                                        <h5>Auto Start Timer</h5>
                                                                        <label for="work_int_auto" class="switch_option capsule_btn p-0">
                                                                            <input type="radio" id="work_int_auto" :value="2" v-model="element.work_interval_type" @change="handleUpdateWorkoutBlock()" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li v-if="element.perform_type == 2">
                                                                    <div class="flex justify-content-between">Default Duration <div class="reset_btn" @click.stop="handleWorkRestApplyButtonConfirmation('work', element)">Apply</div></div>
                                                                    <div class="form_grp py-1 mt-1">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Minutes</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.circuit_settings.work_min" change="handleUpdateWorkoutBlock('work')">
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Seconds</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" max="60" v-model="element.circuit_settings.work_sec" change="handleUpdateWorkoutBlock('work')">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <div class="capsule_elm mt-2">
                                                                <h5>Rest Interval</h5>
                                                                <label :for="`has_rest_int-${index}`" class="switch_option capsule_btn p-0">
                                                                    <input type="checkbox" :id="`has_rest_int-${index}`" :true-value="1" :false-value="0" v-model="element.has_rest_interval" @change="handleUpdateWorkoutBlock()" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <ul class="setting_wpr">
                                                                <li>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Manually Start Timer</h5>
                                                                        <label for="rest_int_manual" class="switch_option capsule_btn p-0">
                                                                            <input type="radio" id="rest_int_manual" :value="1" v-model="element.rest_interval_type" @change="handleUpdateWorkoutBlock()" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="capsule_elm mb-2">
                                                                        <h5>Auto Start Timer</h5>
                                                                        <label for="rest_int_auto" class="switch_option capsule_btn p-0">
                                                                            <input type="radio" id="rest_int_auto" :value="2" v-model="element.rest_interval_type" @change="handleUpdateWorkoutBlock()" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                <li v-if="element.perform_type == 2">
                                                                    <div class="flex justify-content-between">Default Duration <div class="reset_btn" @click.stop="handleWorkRestApplyButtonConfirmation('rest', element)">Apply</div></div>
                                                                    <div class="form_grp py-1 mt-1">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Minutes</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" v-model="element.circuit_settings.rest_min" change="handleUpdateWorkoutBlock('rest')">
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Seconds</label>
                                                                            <div class="field_wpr">
                                                                                <input type="number" min="0" max="60" v-model="element.circuit_settings.rest_sec" change="handleUpdateWorkoutBlock('rest')">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <label class="sec_label">Voice Prompts</label>
                                                            <ul>
                                                                <li>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Block</h5>
                                                                        <label :for="`voice_block-${index}`" class="switch_option capsule_btn p-0">
                                                                            <input type="checkbox" :id="`voice_block-${index}`" v-model="element.is_block_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="sub_control" v-if="element.is_block_voice">
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>10 Sec</h5>
                                                                            <label :for="`block-${index}-ten-sec`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`block-${index}-ten-sec`" v-model="element.has_block_10_sec_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>Starting</h5>
                                                                            <label :for="`block-${index}-starting`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`block-${index}-starting`" v-model="element.has_block_start_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm">
                                                                            <h5>Complete</h5>
                                                                            <label :for="`block-${index}-complete`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`block-${index}-complete`" v-model="element.has_block_complete_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Round</h5>
                                                                        <label :for="`voice_round-${index}`" class="switch_option capsule_btn p-0">
                                                                            <input type="checkbox" :id="`voice_round-${index}`" v-model="element.is_round_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="sub_control" v-if="element.is_round_voice">
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>Starting</h5>
                                                                            <label :for="`round-${index}-starting`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`round-${index}-starting`" v-model="element.has_round_start_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm">
                                                                            <h5>Complete</h5>
                                                                            <label :for="`round-${index}-complete`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`round-${index}-complete`" v-model="element.has_round_complete_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Exercise</h5>
                                                                        <label :for="`voice_exercise-${index}`" class="switch_option capsule_btn p-0">
                                                                            <input type="checkbox" :id="`voice_exercise-${index}`" v-model="element.is_exercise_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="sub_control" v-if="element.is_exercise_voice">
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>Starting</h5>
                                                                            <label :for="`exercise-${index}-starting`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`exercise-${index}-starting`" v-model="element.has_exercise_start_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm">
                                                                            <h5>Complete</h5>
                                                                            <label :for="`exercise-${index}-complete`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`exercise-${index}-complete`" v-model="element.has_exercise_complete_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Work</h5>
                                                                        <label :for="`voice_work-${index}`" class="switch_option capsule_btn p-0">
                                                                            <input type="checkbox" :id="`voice_work-${index}`" v-model="element.is_work_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="sub_control" v-if="element.is_work_voice">
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>20 Sec</h5>
                                                                            <label :for="`work-${index}-twenty-sec`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`work-${index}-twenty-sec`" v-model="element.has_work_20_sec_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>10 Sec</h5>
                                                                            <label :for="`work-${index}-ten-sec`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`work-${index}-ten-sec`" v-model="element.has_work_10_sec_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm">
                                                                            <h5>5 Sec</h5>
                                                                            <label :for="`work-${index}-five-sec`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`work-${index}-five-sec`" v-model="element.has_work_5_sec_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Rest</h5>
                                                                        <label :for="`voice_rest-${index}`" class="switch_option capsule_btn p-0">
                                                                            <input type="checkbox" :id="`voice_rest-${index}`" v-model="element.is_rest_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="sub_control" v-if="element.is_rest_voice">
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>20 Sec</h5>
                                                                            <label :for="`rest-${index}-twenty-sec`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`rest-${index}-twenty-sec`" v-model="element.has_rest_20_sec_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>10 Sec</h5>
                                                                            <label :for="`rest-${index}-ten-sec`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`rest-${index}-ten-sec`" v-model="element.has_rest_10_sec_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm">
                                                                            <h5>5 Sec</h5>
                                                                            <label :for="`rest-${index}-five-sec`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`rest-${index}-five-sec`" v-model="element.has_rest_5_sec_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="capsule_elm mb-3">
                                                                        <h5>Finish</h5>
                                                                        <label :for="`voice_finish-${index}`" class="switch_option capsule_btn p-0">
                                                                            <input type="checkbox" :id="`voice_finish-${index}`" v-model="element.is_finish_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                            <div><span></span></div>
                                                                        </label>
                                                                    </div>
                                                                    <div class="sub_control mb-2" v-if="element.is_finish_voice">
                                                                        <div class="capsule_elm mb-3">
                                                                            <h5>Log Workout</h5>
                                                                            <label :for="`exercise-${index}-log`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`exercise-${index}-log`" v-model="element.has_log_workout_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="capsule_elm">
                                                                            <h5>Workout Confirmation</h5>
                                                                            <label :for="`exercise-${index}-confirmation`" class="switch_option capsule_btn p-0">
                                                                                <input type="checkbox" :id="`exercise-${index}-confirmation`" v-model="element.has_workout_confirmation_voice" @change="handleUpdateWorkoutBlock()" :true-value="1" :false-value="0" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="up_down" @click="handleDuplicateWorkoutBlock(element.id)" v-tooltip="'Duplicate block'" position="top">
                                                        <i class="fa fa-spin fa-spinner" v-if="duplicateBlockLoader && element.id == selectedWorkoutBlock.id"></i>
                                                        <i class="far fa-copy" v-else></i>
                                                    </button>
                                                    <button type="button" class="delete_btn" @click="handleDeleteWorkoutBlock(element.id)" v-tooltip="'Delete'" position="top"><i class="fas fa-trash-alt danger"></i></button>
                                                </div>
                                            </div>
                                            <div v-if="element.name != 'Rest Period'" class="block_panel flex" :class="{active : selectBlockIndex == index}" :style="`background:${element.name == 'Rest Period' ? circle_bg_color : ''};`">
                                                <draggable v-model="element.exercises" tag="ul" item-key="id" :animation="200" @end="handleSortBlocksExercise(index)" handle=".handle-exercise-drag" v-if="element.exercises.length">
                                                    <template #item="{ element : subelement, index : subindex }">
                                                        <li class="move" :key="subindex">
                                                            <h6 class="short_title"><i class="fas fa-arrows-alt handle-exercise-drag"></i><a v-tooltip="subelement.name"><label class="pointer" @click.self="handleEditTitle($event)">{{ subelement.name }}</label></a><a class="icon" v-tooltip="'Settings'"><i class="fas fa-cog" @click="handleEditBlockExercise(subelement)"></i></a></h6>
                                                            <div class="edit_text">
                                                                <input type="text" v-model="subelement.name">
                                                                <span class="save_btn" @click="closeEditTitle(subelement)">Save</span>
                                                            </div>
                                                            <div class="video_wpr exercise" :class="{ video_wpr_flip: subelement.is_flip}">
                                                                <div class="video-overlay" @click="showVideo = true; selectedExercise = subelement;"></div>
                                                                <img :src="subelement.thumb" :alt="subelement.name">
                                                                <div class="block_action">
                                                                    <button type="button" :class="{'flipped' : subelement.is_flip}">
                                                                        <label class="switch_option capsule_btn" :for="`flip-${index}-${subindex}`">
                                                                            <input type="checkbox" :id="`flip-${index}-${subindex}`" :true-value="1" :false-value="0" v-model="subelement.is_flip" @change="handleFlipBlockExercise(subelement)" hidden>
                                                                            <img src="@/assets/images/mirror.svg" alt="mirror">
                                                                        </label>
                                                                    </button>
                                                                    <button type="button" @click="showVideo = true; selectedExercise = subelement;">
                                                                        <img src="@/assets/images/view.svg" alt="view">
                                                                    </button>
                                                                    <button type="button" @click="handleDuplicateBlockExercise(subelement.id)">
                                                                        <img src="@/assets/images/copy.svg" alt="copy">
                                                                    </button>
                                                                    <button type="button" class="danger" :disabled="deleteExerciseLoader" @click.once="handleDeleteBlockExercise(subelement)">
                                                                        <i class="fa fa-spin fa-spinner" v-if="deleteExerciseLoader"></i>
                                                                        <img src="@/assets/images/trash.svg" alt="trash" v-else>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <h6 class="short_info">
                                                                <span class="v_tag" v-if="subelement.is_variable" @click="handleEditBlockExercise(subelement)" v-tooltip="'Variable sets'">V</span>
                                                                <span v-if="element.perform_type != 2" @click.stop="subelement.is_variable ? handleEditBlockExercise(subelement) : handleEdit($event, 'sets')">
                                                                    {{ subelement.sets }} sets |&nbsp;
                                                                </span>
                                                                <span v-if="subelement.measure_type != 2" @click.stop="handleEdit($event, 'reps')">
                                                                    &nbsp;{{ subelement.is_reps_ranges ? `${subelement.min_reps ? subelement.min_reps : 0}-${subelement.max_reps ? subelement.max_reps : 0}` : (subelement.is_failure ? 'max' : subelement.exercise_reps) }} reps |
                                                                </span>
                                                                <span v-if="(subelement.measure_type == 2 && subelement.exercise_minutes) || (subelement.measure_type == 2 && subelement.exercise_seconds)" @click.stop="handleEdit($event, 'duration')">
                                                                    <template v-if="subelement.measure_type == 2 && subelement.exercise_minutes">
                                                                        {{ subelement.exercise_minutes }} min 
                                                                    </template>
                                                                    <template v-if="subelement.measure_type == 2 && subelement.exercise_seconds">
                                                                        {{ subelement.exercise_seconds}} sec 
                                                                    </template>
                                                                    |
                                                                </span>
                                                                <span v-if="subelement.load_unit_code == '1RM'" @click.stop="handleEdit($event, 'unit')">
                                                                    &nbsp;{{ `${subelement.load_number}% of 1RM` }}
                                                                </span>
                                                                <span v-else-if="subelement.load_unit_code == 'BW'" @click.stop="handleEdit($event, 'unit')">&nbsp;bodyweight</span>
                                                                <span v-else @click.stop="handleEdit($event, 'unit')">
                                                                    &nbsp;{{ filterLoadNumber(subelement) }} {{ subelement.load_unit_code_v2.toLowerCase() }} 
                                                                </span>
                                                                <div class="dropdown_wpr" @click.stop>
                                                                    <Form class="workout-block-exercise-setting-form" v-slot="{ errors }" ref="workout-block-exercise-setting-form">
                                                                        <div class="scope_item" v-if="selectedType == 'sets'">
                                                                            <label class="input_label">Sets</label>
                                                                            <div class="column-2">
                                                                                <div class="item">
                                                                                    <div class="field_wpr">
                                                                                        <input type="number" min="0" v-model="subelement.sets">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="capsule_elm pt-3">
                                                                                <h5>Variable</h5>
                                                                                <label class="switch_option capsule_btn p-0" :for="`variable-${index}-${subindex}`">
                                                                                    <input type="checkbox" :id="`variable-${index}-${subindex}`" :true-value="1" :false-value="0" v-model="subelement.is_variable" hidden @change="handleExerciseVariableChange(subelement)">
                                                                                    <div><span></span></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="scope_item" v-if="selectedType == 'reps'">
                                                                            <label class="input_label">Reps</label>
                                                                            <div class="column-2" v-if="subelement.is_reps_ranges">
                                                                                <div class="item">
                                                                                    <label class="fld_label">Min</label>
                                                                                    <div class="field_wpr" :class="{ 'has-error': errors[`min_reps_${index}`] }">
                                                                                        <Field autocomplete="off" type="number" :name="`min_reps_${index}`" min="1" v-model="subelement.min_reps" rules="required|min_value:1" label="min reps" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`min_reps_${index}`" class="text-danger" />
                                                                                </div>
                                                                                <div class="item">
                                                                                    <label class="fld_label">Max</label>
                                                                                    <div class="field_wpr" :class="{ 'has-error': errors[`max_reps_${index}`] }">
                                                                                        <Field autocomplete="off" type="number" :name="`max_reps_${index}`" min="1" v-model="subelement.max_reps" rules="required|min_value:1" label="max reps" />
                                                                                    </div>
                                                                                    <ErrorMessage :name="`max_reps_${index}`" class="text-danger" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="column-2" v-else>
                                                                                <div class="item">
                                                                                    <div class="field_wpr">
                                                                                        <input type="text" disabled placeholder="Max" v-if="subelement.is_failure">
                                                                                        <input type="number" min="0" v-model="subelement.exercise_reps" v-else>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="capsule_elm pt-3">
                                                                                <h5>Use Rep Ranges</h5>
                                                                                <label class="switch_option capsule_btn p-0" :for="`is_rep_range-${index}-${subindex}`" v-if="subelement.measure_type !== 2">
                                                                                    <input type="checkbox" :id="`is_rep_range-${index}-${subindex}`" :true-value="1" :false-value="0" v-model="subelement.is_reps_ranges" hidden @c2hange="handleUpdateBlockExercise(subelement)">
                                                                                    <div><span></span></div>
                                                                                </label>
                                                                            </div>
                                                                            <div class="capsule_elm pt-3" v-if="!subelement.is_reps_ranges">
                                                                                <h5>To Failure</h5>
                                                                                <label class="switch_option capsule_btn p-0" :for="`failure-${index}-${subindex}`" v-if="subelement.measure_type !== 2">
                                                                                    <input type="checkbox" :id="`failure-${index}-${subindex}`" :true-value="1" :false-value="0" v-model="subelement.is_failure" hidden @c2hange="handleUpdateBlockExercise(subelement)">
                                                                                    <div><span></span></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="scope_item" v-if="selectedType == 'duration'">
                                                                            <label class="input_label">Work Time</label>
                                                                            <div class="column-2">
                                                                                <div class="item">
                                                                                    <label class="fld_label">Minutes</label>
                                                                                    <div class="field_wpr">
                                                                                        <input type="number" min="0" max="60" v-model="subelement.exercise_minutes">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="item">
                                                                                    <label class="fld_label">Seconds</label>
                                                                                    <div class="field_wpr">
                                                                                        <input type="number" min="0" max="60" v-model="subelement.exercise_seconds">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="scope_item" v-if="selectedType == 'unit'">
                                                                            <label class="input_label">Load</label>
                                                                            <div class="capsule_elm pb-3">
                                                                                <h5>Bodyweight Only</h5>
                                                                                <label class="switch_option capsule_btn p-0" :for="`bodyweight-${index}-${subindex}`">
                                                                                    <input type="checkbox" :id="`bodyweight-${index}-${subindex}`" :true-value="1" :false-value="0" v-model="subelement.is_bodyweight" @change="handleUnitChange(subelement)" hidden>
                                                                                    <div><span></span></div>
                                                                                </label>
                                                                            </div>
                                                                            <div class="item" v-if="!subelement.is_bodyweight && subelement.load_unit !== 'Light' && subelement.load_unit !== 'Medium' && subelement.load_unit !== 'Heavy' && subelement.load_unit !== 'Easy' && subelement.load_unit !== 'Moderate' && subelement.load_unit !== 'Hard'">
                                                                                <label class="fld_label">Number</label>
                                                                                <div class="field_wpr" :class="{ 'has-error': errors[`load_number_${index}`] }">
                                                                                    <Field autocomplete="off" type="number" :name="`load_number_${index}`" min="0" v-model="subelement.load_number" rules="required|min_value:0" label="load" />
                                                                                </div>
                                                                                <ErrorMessage :name="`load_number_${index}`" class="text-danger" />
                                                                            </div>
                                                                            <div class="item mt-2">
                                                                                <label class="fld_label">Unit</label>
                                                                                <div class="field_wpr" :class="{'disabled' : subelement.is_bodyweight}">
                                                                                    <multiselect v-model="subelement.load_unit" :options="loadUnitCodes" :disabled="subelement.is_bodyweight == 1"></multiselect>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="action_wpr border-0 p-0 mt-4 mb-0">
                                                                            <button type="button" class="btn cancel_btn m-0" @click="closeEditDropdown(true)">Cancel</button>
                                                                            <button type="button" class="btn save_btn" @click="handleUpdateBlockExercise(subelement)">Save</button>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                            </h6>
                                                            <div class="rest-dropdown-container">
                                                                <div class="circuit" v-if="subelement.is_rest && (subelement.rest_minutes || subelement.rest_seconds)" @click.stop="handleEditRest(subelement.id)">
                                                                    <i class="fas fa-arrow-alt-circle-right"></i>
                                                                    <a v-if="subelement.is_rest && (subelement.rest_minutes || subelement.rest_seconds)">
                                                                        <a v-if="subelement.rest_minutes">{{subelement.rest_minutes}} Min </a>
                                                                        <a v-if="subelement.rest_seconds">{{subelement.rest_seconds}} Sec</a>
                                                                    </a>
                                                                </div>
                                                                <div class="infinity pointer" @click.stop="handleEditRest(subelement.id)" v-else v-tooltip="element.perform_type == 1 ? 'Super Set' : 'No rest'">
                                                                    <i class="fas fa-infinity"></i>
                                                                </div>
                                                                <div class="dropdown_wpr rest-dropdown-wrapper" :id="`rest-dropdown-wrapper-${subelement.id}`" @click.stop>
                                                                    <div class="scope_item">
                                                                        <div class="capsule_elm pb-3">
                                                                            <h5>Rest Time</h5>
                                                                            <label class="switch_option capsule_btn p-0" :for="`has_rest-${index}-${subindex}`">
                                                                                <input type="checkbox" :id="`has_rest-${index}-${subindex}`" :true-value="1" :false-value="0" v-model="subelement.is_rest" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="column-2">
                                                                            <div class="item">
                                                                                <label class="fld_label">Minutes</label>
                                                                                <div class="field_wpr">
                                                                                    <input type="number" min="0" v-model="subelement.rest_minutes">
                                                                                </div>
                                                                            </div>
                                                                            <div class="item">
                                                                                <label class="fld_label">Seconds</label>
                                                                                <div class="field_wpr">
                                                                                    <input type="number" min="0" max="60" v-model="subelement.rest_seconds">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="action_wpr border-0 p-0 mt-4 mb-0">
                                                                        <button type="button" class="btn cancel_btn m-0" @click.stop="closeEditRest(true)">Cancel</button>
                                                                        <button type="button" class="btn save_btn" @click="handleUpdateBlockExercise(subelement)">Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </template>
                                                </draggable>
                                                <div class="add_item" :class="{'minimal' : element.exercises.length}" @click.stop="handleAddOption($event, index, element)" v-click-outside="closeAddOption">
                                                    <button type="button"><i class="fas fa-plus"></i><label>Add Exercise</label></button>
                                                    <div class="dropdown_wpr">
                                                        <div class="drop_item" @click="blinkAddExercise">Choose from library</div>
                                                        <div class="drop_item" @click="newCustomExercise = true;">Add new custom exercise</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </draggable>
                                <div v-if="addDropdownWhiteSpace" id="dropdown-white-space-block" class="dropdown-white-space-block"></div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="workoutStep == 3">
                            <div class="section_content w-100 pt-1">
                                <div class="section_header pt-0">
                                    <h2>Public Page</h2>
                                    <button type="button" class="video_btn" @click="handleTutorialVideo()"><i class="fas fa-play"></i></button>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp p-0 mt-1 mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Public Url</label>
                                                    <div class="field_wpr has_suffix">
                                                        <Field autocomplete="off" type="text" v-model="form.url" name="url" placeholder="https://superfitcoaching.com" readonly />
                                                        <span class="suffix pointer" @click="handleCopy(form.url)">Copy</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="hdrsetting" @click="goTo('hdrsection')">
                                <div class="section_header">
                                    <h2>Header</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_header ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_header ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show</div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="display_header" class="switch_option capsule_btn">
                                                <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="form.public_settings.display_header" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr content_details mt-2">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Company Name <a class="reset-default" @click="form.public_settings.company_branding = defaultWorkoutForm.public_settings.company_branding">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                                            <Field autocomplete="off" type="text" name="company_branding" v-model="form.public_settings.company_branding" placeholder="Company Name" />
                                                        </div>
                                                        <ErrorMessage name="company_branding" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Header Background Color <a class="reset-default" @click="form.public_settings.header_bgcolor = defaultWorkoutForm.public_settings.header_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="header_bgcolor" type="text" label="header background color">
                                                            <color-picker v-model="form.public_settings.header_bgcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="header_bgcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Header Text Color <a class="reset-default" @click="form.public_settings.header_textcolor = defaultWorkoutForm.public_settings.header_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="header_textcolor" type="text" label="header text color">
                                                            <color-picker v-model="form.public_settings.header_textcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="header_textcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <ul class="tab_sec mt-2">
                                                    <li @click="headerTab = 'logo'" :class="{ active: headerTab === 'logo' }">Header Logo</li>
                                                    <li @click="headerTab = 'author'" :class="{ active: headerTab === 'author' }">Header Author Image</li>
                                                </ul>
                                                <div class="setting_wpr">
                                                    <div v-if="headerTab === 'logo'">
                                                        <label for="show_logo" class="switch_option capsule_btn py-3">
                                                            <h5 class="large">Display Logo</h5>
                                                            <input type="checkbox" id="show_logo" :true-value="1" :false-value="0" v-model="form.public_settings.display_logo" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.public_settings.display_logo">
                                                            <image-library image-type="public-logo" upload-text="Logo" v-model="form.public_settings.logo" />
                                                        </div>
                                                    </div>
                                                    <div v-if="headerTab === 'author'">
                                                        <label for="coach_img" class="switch_option capsule_btn py-3">
                                                            <h5 class="large">Display Author</h5>
                                                            <input type="checkbox" id="coach_img" :true-value="1" :false-value="0" v-model="form.public_settings.display_author" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.public_settings.display_author">
                                                            <image-library image-type="avatar" :is-avatar="true" upload-text="Image" v-model="form.public_settings.author" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header"  @click="goTo('expvidsection')">
                                    <h2>Plan Explainer Video</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_explainer_video ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_explainer_video ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show</div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="explain_video" class="switch_option capsule_btn">
                                                <input type="checkbox" id="explain_video" :true-value="1" :false-value="0" v-model="form.public_settings.display_explainer_video" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr content_details mt-3">
                                                <div class="border-bottom pb-2">
                                                    <label for="video_url" class="switch_option capsule_btn border-0">
                                                        <h5 class="large">Video Link (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                        <input type="radio" id="video_url" value="0" v-model="form.public_settings.explainer_video_type" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr mb-2" v-if="form.public_settings.explainer_video_type == 0">
                                                        <div class="form_grp pt-0">
                                                            <div class="group_item">
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.explainer_video }">
                                                                    <Field autocomplete="off" type="text" name="explainer_video" v-model="form.public_settings.explainer_video" placeholder="Video Link Goes Here..." rules="url" label="explainer video" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <ErrorMessage name="explainer_video" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="py-2">
                                                    <label for="embed_video" class="switch_option capsule_btn border-0">
                                                        <h5 class="large">Embeded Code (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                        <input type="radio" id="embed_video" value="1" v-model="form.public_settings.explainer_video_type" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr" v-if="form.public_settings.explainer_video_type == 1">
                                                        <div class="form_grp pt-0">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.explainer_embed }">
                                                                    <Field autocomplete="off" type="text" name="explainer_embed" v-model="form.public_settings.explainer_embed" label="explainer embed code" :class="{ 'has-error': errors.explainer_embed }">
                                                                        <textarea cols="30" rows="10" v-model="form.public_settings.explainer_embed" placeholder="Embed code goes here.."></textarea>
                                                                    </Field>
                                                                </div>
                                                                <ErrorMessage name="explainer_embed" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Optin Setting</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <label for="workout-optin-phone-field" class="switch_option capsule_btn">
                                            <h5>Workout Optin Phone</h5>
                                            <input type="checkbox" id="workout-optin-phone-field" :true-value="1" :false-value="0" v-model="form.has_optin_phone" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Color</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Accent Color <a class="reset-default" @click="form.public_settings.element_color = defaultWorkoutForm.public_settings.element_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.element_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <a class="reset-default" @click="form.public_settings.element_text_color = defaultWorkoutForm.public_settings.element_text_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_text_color" type="text" label="label text color">
                                                        <color-picker v-model="form.public_settings.element_text_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Block Background Color <a class="reset-default" @click="form.public_settings.block_bg_color = defaultWorkoutForm.public_settings.block_bg_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.block_bg_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Timer Color <a class="reset-default" @click="form.public_settings.timer_color = defaultWorkoutForm.public_settings.timer_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="label_bg_color" type="text" label="label background color">
                                                        <color-picker v-model="form.public_settings.timer_color" />
                                                    </Field>
                                                    <ErrorMessage name="label_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Submit Button</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div ref="subbtnsetting"  @click="goTo('subbtnsection')">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Text <a class="reset-default" @click="form.public_settings.submit_btn_text = defaultWorkoutForm.public_settings.submit_btn_text">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.name }">
                                                            <Field autocomplete="off" type="text" name="name" v-model="form.public_settings.submit_btn_text" placeholder="ex: Submit Plan" rules="required" />
                                                        </div>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Color <a class="reset-default" @click="form.public_settings.submit_btn_bgcolor = defaultWorkoutForm.public_settings.submit_btn_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_bg_color" type="text" label="button background color">
                                                            <color-picker v-model="form.public_settings.submit_btn_bgcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_bg_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Text Color <a class="reset-default" @click="form.public_settings.submit_btn_textcolor = defaultWorkoutForm.public_settings.submit_btn_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_text_color" type="text" label="button text color">
                                                            <color-picker v-model="form.public_settings.submit_btn_textcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Footer</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_footer ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_footer ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <div class="section_title m-0">
                                            <div class="mr-auto">Show</div>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="has-login-footer" class="switch_option capsule_btn">
                                                <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.public_settings.display_footer" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="mt-5 content_details">
                                                <label for="facebook" class="switch_option capsule_btn p-0 border-0">
                                                    <h5 class="large">Facebook</h5>
                                                    <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_facebook" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_facebook">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.public_settings.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                            </div>
                                                            <ErrorMessage name="footer_facebook" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="twitter" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Twitter</h5>
                                                    <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_twitter" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_twitter">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.public_settings.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                            </div>
                                                            <ErrorMessage name="footer_twitter" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="instagram" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Instagram</h5>
                                                    <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_instagram" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_instagram">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.public_settings.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                            </div>
                                                            <ErrorMessage name="footer_instagram" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="linkedin" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Linkedin</h5>
                                                    <input type="checkbox" id="linkedin" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_linkedin" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_linkedin">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_linkedin" v-model="form.public_settings.footer_linkedin" placeholder="Linkedin link here" rules="url|validate_url:linkedin" label="linkedin" />
                                                            </div>
                                                            <ErrorMessage name="footer_linkedin" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="youtube" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                    <h5 class="large">Youtube</h5>
                                                    <input type="checkbox" id="youtube" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_youtube" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.public_settings.footer_has_youtube">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_youtube" v-model="form.public_settings.footer_youtube" placeholder="Youtube link here" rules="url|validate_url:youtu" label="youtube" />
                                                            </div>
                                                            <ErrorMessage name="footer_youtube" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Icons Color <a class="reset-default" @click="form.public_settings.footer_textcolor = defaultWorkoutForm.public_settings.footer_textcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="footer_textcolor" v-model="form.public_settings.footer_textcolor" type="text" label="element color">
                                                                <color-picker v-model="form.public_settings.footer_textcolor"/>
                                                            </Field>
                                                            <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.footer_bgcolor = defaultWorkoutForm.public_settings.footer_bgcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="footer_bgcolor" v-model="form.public_settings.footer_bgcolor" type="text" label="element color">
                                                                <color-picker v-model="form.public_settings.footer_bgcolor"/>
                                                            </Field>
                                                            <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Copyright Text</label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_company" v-model="form.public_settings.footer_company" :placeholder="`© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}`" />
                                                            </div>
                                                            <ErrorMessage name="footer_company" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Terms Link</label>
                                                            <div class="field_wpr has_prefix m-0">
                                                                <Field autocomplete="off" type="text" name="footer_terms" v-model="form.public_settings.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                                                <span class="prefix">URL</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="footer_terms" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Privacy Link</label>
                                                            <div class="field_wpr has_prefix m-0">
                                                                <Field autocomplete="off" type="text" name="footer_policy" v-model="form.public_settings.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                                                <span class="prefix">URL</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="footer_policy" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="workoutStep == 4">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Notifications</h2>
                                    <button type="button" class="video_btn" @click="handleTutorialVideo()"><i class="fas fa-play"></i></button>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <swiper :slidesPerView="'auto'" :spaceBetween="0" :navigation='true' class="tab_slider">
                                            <swiper-slide class="mr-3"><span @click="reminderTab = 'reminders'" :class="{ active: reminderTab === 'reminders' }">Delivery Message <label class="tag" :style="`background: #${form.reminder.has_reminder ? '2f7eed' : 'f2a31d'}`">{{ form.reminder.has_reminder ? 'On' : 'Off' }}</label></span></swiper-slide>
                                            <swiper-slide class="mr-3"><span @click="reminderTab = 'admin'" :class="{ active: reminderTab === 'admin' }">Admin Notiication <label class="tag" :style="`background: #${form.reminder.has_notification ? '2f7eed' : 'f2a31d'}`">{{ form.reminder.has_notification ? 'On' : 'Off' }}</label></span></swiper-slide>
                                            <swiper-slide><span @click="reminderTab = 'award'" :class="{ active: reminderTab === 'award' }">Award Points <label class="tag" :style="`background: #${form.rewards_settings.has_reward_points ? '2f7eed' : 'f2a31d'}`">{{ form.rewards_settings.has_reward_points ? 'On' : 'Off' }}</label></span></swiper-slide>
                                        </swiper>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'reminders'">
                                                <label for="track_reminders" class="switch_option capsule_btn">
                                                    <h5 class="large">Delivery Message</h5>
                                                    <input type="checkbox" id="track_reminders" :true-value="1" :false-value="0" v-model="form.reminder.has_reminder" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_reminder" class="mt-2 outer">
                                                        <div class="edit_section border mb-3">
                                                            <sending-method v-model="form.reminder.sending_method" />
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'admin'">
                                                <label for="adm_notification" class="switch_option capsule_btn">
                                                    <h5 class="large">Admin Notification</h5>
                                                    <input type="checkbox" id="adm_notification" :true-value="1" :false-value="0" v-model="form.reminder.has_notification" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_notification" class="mt-2 outer">
                                                        <div class="edit_section border mb-3">
                                                            <sending-method v-model="form.reminder.notification_sending_method" />
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div class="outer" v-if="reminderTab === 'award'">
                                                <h3 class="coming_soon">Coming Soon</h3>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                                <template v-if="reminderTab === 'reminders'">
                                    <div class="setting_wpr mt-3">
                                        <div v-if="form.reminder.sending_method == 1 || form.reminder.sending_method == 3">
                                            <email-component v-model="reminderEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleReminderDefaultEmail" preview-module="plan" ref="email-component" is-full-view />
                                        </div>
                                        <div v-if="form.reminder.sending_method == 2 || form.reminder.sending_method == 3" class="sms-component">
                                            <sms-component v-model="form.reminder.sms_message" media-field-name="reminder_sms_media" :media="form.reminder.media" :default-sms-handler="true" preview-module="plan" :handle-default-sms="handleReminderDefaultSms" :update-sms-text="updateSmsText()" ref="message-sms-component" />
                                        </div>
                                    </div>
                                </template>
                                <template v-if="reminderTab === 'admin'">
                                    <div class="setting_wpr mt-3">
                                        <div v-if="form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3">
                                            <div class="edit_section mb-3">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Email Recipient</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_email_recipient }">
                                                            <Field autocomplete="off" name="notification_email_recipient" v-model="form.reminder.notification_email_recipient" rules="required" label="email recipient">
                                                                <vue-tags-input :tags="form.reminder.notification_email_recipient" @on-tags-changed="handleNotificationEmailRecipient" />
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="notification_email_recipient" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <email-component v-model="notificationEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleNotificationDefaultEmail" preview-module="plan" ref="notification-email-component" is-full-view />
                                        </div>
                                        <div v-if="form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3" class="sms-component">
                                            <div class="edit_section mb-3">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">SMS Recipient</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_sms_recipient }">
                                                            <Field autocomplete="off" name="notification_sms_recipient" v-model="form.reminder.notification_sms_recipient" rules="required" label="sms recipient">
                                                                <vue-tags-input :tags="form.reminder.notification_sms_recipient" @on-tags-changed="handleNotificationSMSRecipient" />
                                                            </Field>
                                                        </div>
                                                        <ErrorMessage name="notification_sms_recipient" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <sms-component v-model="form.reminder.notification_sms_message" media-field-name="notification_sms_media" :media="form.reminder.notification_media" :default-sms-handler="true" preview-module="plan" :handle-default-sms="handleNotificationDefaultSms" :update-sms-text="updateSmsText()" ref="notification-sms-component" />
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="workoutStep == 5">
                            <div class="section_content w-100 open_area" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO Settings</h2>
                                    <button type="button" class="video_btn" @click="handleTutorialVideo()"><i class="fas fa-play"></i></button>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                    <Field autocomplete="off" type="text" name="seo_title" placeholder="ex: The title will be displayed in browser tabs." v-model="form.public_settings.seo_title" />
                                                </div>
                                                <ErrorMessage name="seo_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Keywords</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                    <Field autocomplete="off" type="text" name="seo_keyword" placeholder="ex: Several keywords that describe your page best." v-model="form.public_settings.seo_keyword" />
                                                </div>
                                                <ErrorMessage name="seo_keyword" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_desc" v-model="form.seo_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="page_indexed" class="switch_option capsule_btn border-0">
                                        <h5 class="large">I want my page indexed by search engine</h5>
                                        <input type="checkbox" id="page_indexed" :true-value="1" :false-value="0" v-model="form.public_settings.seo_index" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                    <Field autocomplete="off" type="text" name="seo_share_title" placeholder="ex: New Title" v-model="form.public_settings.seo_share_title" />
                                                </div>
                                                <ErrorMessage name="seo_share_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_share_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_share_desc" v-model="form.public_settings.seo_share_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_share_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_share_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-4">SEO Thumbnail</h3>
                                    <image-library v-model="form.public_settings.seo_thumb" image-type="social-share" />
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <image-library v-model="form.public_settings.seo_fav" image-type="favicon" />
                                </div>
                            </div>
                        </div>

                        <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="workoutUpdateLoader" type="button" class="btn cancel_btn" @click="closeModal()" v-if="workoutStep == 2">Close</button>
                        <button :disabled="workoutUpdateLoader" type="button" class="btn cancel_btn" @click="workoutStep -= 1" v-if="workoutStep > 2">Back</button>
                        <button :disabled="workoutUpdateLoader" class="btn save_btn" id="workout-save-button" v-if="workoutStep < 5"><i class="fa fa-spinner fa-spin" v-if="workoutUpdateLoader"></i> {{ workoutUpdateLoader ? 'Saving' : 'Next' }}</button>
                        <button :disabled="workoutUpdateLoader" class="btn save_btn" v-if="workoutStep == 5"><i class="fa fa-spin fa-spinner" v-if="workoutUpdateLoader"></i>{{ workoutUpdateLoader ? ' Saving' : 'Save' }}</button>
                    </div>
                </Form>
            </div>
            <workout-wizard-exercise v-model="preview" :add-to-block="addToBlock" v-if="workoutStep == 1 || workoutStep == 2" ref="workout-wizard-exercise"></workout-wizard-exercise>
            <div class="preview_content" :class="{ show : preview }" v-if="workoutStep == 3">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <button type="button" class="video_btn" @click="handleTutorialVideo()"><i class="fas fa-play"></i></button>
                        <label for="full_preview" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="large">Full View</h5>
                            <input type="checkbox" id="full_preview" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area workout_scroll">
                        <plan-workout-preview :workout="activeWorkout" :public-settings="form.public_settings" :is-plan="false"></plan-workout-preview>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="workoutStep == 4">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_notification" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_notification" v-model="previewReminder" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="reminderTab == 'reminders' && clientReminderTab == 'client' && (form.reminder.sending_method == 1 || form.reminder.sending_method == 3)">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="reminderTab == 'reminders' && clientReminderTab == 'client' && (form.reminder.sending_method == 2 || form.reminder.sending_method == 3)">SMS</li>
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="reminderTab == 'admin' && (form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3)">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="reminderTab == 'admin' && (form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3)">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="reminderPreviewType == 'email'">
                        <div class="msg_preview" v-if="reminderTab == 'reminders' && clientReminderTab == 'client'">
                            <h2>{{ replaceVariables(reminderEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="reminderEmail.show_email_logo">
                                <img :src="reminderEmail.email_logo ? reminderEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(reminderEmail.email, reminderEmail.email_button_bg_color, reminderEmail.email_button_text_color, 'View Workout')"></div>
                            <div v-html="generateSignature(reminderEmail.is_signature, reminderEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="reminderTab == 'admin'">
                            <h2>{{ replaceVariables(notificationEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="notificationEmail.show_email_logo">
                                <img :src="notificationEmail.email_logo ? notificationEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(notificationEmail.email, notificationEmail.email_button_bg_color, notificationEmail.email_button_text_color, 'View Response')"></div>
                            <div v-html="generateSignature(notificationEmail.is_signature, notificationEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="reminderTab == 'award'">
                            <h2>{{ replaceVariables(rewardDetail.awarded_subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="rewardDetail.reward_show_email_logo">
                                <img :src="rewardDetail.reward_email_logo ? rewardDetail.reward_email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(rewardDetail.awarded_email, rewardDetail.email_button_bg_color, rewardDetail.email_button_text_color, 'View Your Plan')"></div>
                            <div v-html="generateSignature(rewardDetail.is_reward_signature, rewardDetail.reward_signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="reminderPreviewType == 'sms'">
                        <div class="sms_preview">
                            <div v-if="reminderTab == 'reminders' && clientReminderTab == 'client'" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(messageSmsText, '', '', '', '', false)"></div>
                            <div v-if="reminderTab == 'admin'" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(notificationSmsText, '', '', '', '', false)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="workoutStep == 5">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4 v-html="form.public_settings.seo_title ? replaceVariables(form.public_settings.seo_title) : 'Lorem Ipsum Dolor'"></h4>
                        <p>{{ form.public_settings.seo_desc ? form.public_settings.seo_desc : 'It takes whole chocolate protein. The members of the fleet are turpis at the ends of the pulvinar. No vehicles are accepted at the bed.' }}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="form.public_settings.seo_thumb ? form.public_settings.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.public_settings.seo_share_title ? replaceVariables(form.public_settings.seo_share_title) : 'Optimize Your Metabolism In 30 Days' }}</h3>
                            <p>{{ form.public_settings.seo_share_desc ? form.public_settings.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.public_settings.seo_fav ? form.public_settings.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.public_settings.seo_title ? (form.public_settings.seo_title.length > 15 ? replaceVariables(form.public_settings.seo_title).substr(0, 15)+'...' : form.public_settings.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal secondary video_player" v-if="showVideo">
                <div class="modal_container">
                    <div class="modal_header">
                        <button class="close_btn" @click="showVideo = false"><i class="fas fa-times"></i></button>
                    </div >
                    <div class="video_wpr" :class="{ video_wpr_flip: selectedExercise.is_flip}">
                        <div v-if="selectedExercise.has_gif == 1 && selectedExercise.gif_image" class="player_wpr">
                            <img :src="selectedExercise.gif_image" :alt="selectedExercise.name">
                        </div>
                        <div v-else-if="selectedExercise.video_type == 1" class="player_wpr" v-html="parseEmbedCode(selectedExercise.video_link)"></div>
                        <div class="player_wpr" v-else-if="selectedExercise.video_type == 2" v-html="selectedExercise.embed_code"></div>
                    </div>
                </div>
            </div>
            <div class="modal secondary perform-type-confirmation" v-if="toggleBlockConfirmation">
                <div class="modal_container">
                    <div class="modal_header bg-transparent">
                        <h5>Need help deciding?<i class="fas fa-volume-up ml-2" @click="playPauseBlockDecidingSound()"></i></h5>
                    </div>
                    <div class="model_body">
                        <template v-if="selectedBlockForm.default_perform_type == 1">
                            <div class="capsule_elm pb-2">
                                <h5><p class="recommended">Recommended</p>Perform as a progression</h5>
                                <label for="perform-progression-toggle" class="switch_option capsule_btn">
                                    <input type="radio" id="perform-progression-toggle" value="1" v-model="selectedBlockForm.perform_type" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="capsule_elm">
                                <h5>Perform as a circuit</h5>
                                <label for="perform-circuit-toggle" class="switch_option capsule_btn">
                                    <input type="radio" id="perform-circuit-toggle" value="2" v-model="selectedBlockForm.perform_type" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </template>
                        <template v-else>
                            <div class="capsule_elm pb-2">
                                <h5><p class="recommended">Recommended</p>Perform as a circuit</h5>
                                <label for="perform-circuit-toggle" class="switch_option capsule_btn">
                                    <input type="radio" id="perform-circuit-toggle" value="2" v-model="selectedBlockForm.perform_type" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="capsule_elm">
                                <h5>Perform as a progression</h5>
                                <label for="perform-progression-toggle" class="switch_option capsule_btn">
                                    <input type="radio" id="perform-progression-toggle" value="1" v-model="selectedBlockForm.perform_type" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </template>
                        <div class="action_wpr mt-4">
                            <button type="button" class="btn cancel_btn" @click="toggleBlockConfirmation = false">Close</button>
                            <button :disabled="createBlockLoader" class="btn save_btn" @click="handleBlockTypeConfirmation()">
                                <i class="fa fa-spin fa-spinner" v-if="createBlockLoader"></i> {{ createBlockLoader ? 'Adding' : 'Add'}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal secondary prompt" v-if="workRestApplyButtonConfirmation" @click.stop>
                <div class="modal_container">
                    <img src="@/assets/images/alert.svg" alt="" class="alert_img mb-5">
                    <template v-if="workRestApplyButtonConfirmationType == 'work'">
                        <div class="title">You have variable work intervals set.</div>
                        <h5>Resetting this globally will override your variable work intervals. Proceed?</h5>
                    </template>
                    <template v-if="workRestApplyButtonConfirmationType == 'rest'">
                        <div class="title">You have variable rest intervals set.</div>
                        <h5>Resetting this globally will override your variable rests. Proceed?</h5>
                    </template>
                    <div class="action_wpr mt-3">
                        <button type="button" class="btn cancel_btn" @click="workRestApplyButtonConfirmation = false;">Cancel</button>
                        <button type="button" class="btn save_btn" @click="handleUpdateWorkoutBlock(workRestApplyButtonConfirmationType)"> <i class="fa fa-spin fa-spinner" v-if="updateBlockLoader"></i> {{ updateBlockLoader ? 'Updating' : 'Yes' }}</button>
                    </div>
                </div>
            </div>

            <add-smart-block v-model="addSmartBlock" :workout-id="form.id"></add-smart-block>
            <edit-block-exercise v-model="editBlockExercise" :selected-exercise="selectedExercise" :selected-workout-block="selectedWorkoutBlock"></edit-block-exercise>
        </div>
        <preview-workout v-model="fullPreview" :workout="form" />
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
        <workout-reminder-preview
            v-model="previewReminder"
            :reminder-tab="reminderTab"
            :form="form"
            :reminder-email="reminderEmail"
            :notification-email="notificationEmail"
            :reminderPreviewType="reminderPreviewType"
            :messageSmsText="messageSmsText"
            :notificationSmsText="notificationSmsText"
        />
        <new-exercise v-model="newCustomExercise" :selected-workout-block="selectedWorkoutBlock"/>
        <div class="add_exercise_guide" :class="{'show' : showExerciseGuide && !disableExerciseGuide}">
            <h4>Click the "<span>+Add to block</span>" link above your desired exercise to add it to your block.</h4>
            <label for="disable_exercise_guide" class="checkbox">
                <input type="checkbox" id="disable_exercise_guide" v-model="disableExerciseGuide" :true-value="1" :false-value="0" hidden @change="handleDisableExerciseGuide()">
                <span><i class="fas fa-check"></i></span> Don't show it again.
            </label>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate';
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import { copyText } from 'vue3-clipboard'
    import SwiperCore, { Navigation } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'

    const WorkoutWizardExercise = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutWizardExercise'))
    const AddSmartBlock = defineAsyncComponent(() => import('@/pages/workout/components/AddSmartBlock'))
    const EditBlockExercise = defineAsyncComponent(() => import('@/pages/workout/components/EditBlockExercise'))
    const PreviewWorkout = defineAsyncComponent(() => import('@/pages/workout/components/PreviewWorkout'))
    const WorkoutReminderPreview = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutReminderPreview'))
    const PlanWorkoutPreview = defineAsyncComponent(() => import('@/pages/workout/components/plan/PlanWorkoutPreview'))
    const NewExercise = defineAsyncComponent(() => import('@/pages/workout/components/NewExercise'))

    import moment from 'moment'
    import Draggable from 'vuedraggable'
    import Swal from 'sweetalert2'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import VideoParser from '@/utils/VideoParser'
    import VueTagsInput from 'vue3-tags-input'
    import 'swiper/swiper-bundle.min.css'

    const blockSelectionHelper = new Audio(require('@/assets/audio/workout/block-selection-helper.mp3'))

    export default {
        name: 'Workout Wizard',

        data () {
            return {
                tutorial: false,
                tutorialVideo: '',
                showDescription: false,
                form: {
                    name: '',
                    access_code: "",
                    url: "",
                    reminder: {
                        has_reminder: 1,
                        sending_method: 1,
                        throttle_for_user_tz: 1,
                        recipient_email:'',
                        recipient_mobile:'',
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: [],
                        show_email_logo: true,
                        show_email_signature: false,
                        signature_id: 0,
                        logo_image: '',
                        has_notification: 0,
                        notification_sending_method: 1,
                        notification_email_subject: '',
                        notification_email_message: '',
                        notification_sms_message: '',
                        show_notification_email_logo: true,
                        notification_logo_image: '',
                        notification_media: [],
                        notification_email_recipient: [this.user ? this.user.email : ''],
                        notification_sms_recipient: [this.user ? this.user.sms_notification_number : ''],
                        show_signature: 0,
                        show_notification_signature: 0,
                        notification_signature_id: 0,
                        email_button_bg_color: '',
                        email_button_text_color: '',
                        notification_email_button_bg_color: '',
                        notification_email_button_text_color: '',
                    },
                    rewards_settings: {
                        has_reward_points: 0,
                        reward_points: 0,
                        reward_notif: 0,
                        reward_notif_method: 1,
                        reward_reason: 'submitting your workout plan report',
                    },
                    public_settings: {
                        workout_type: 'single',
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: "",
                        explainer_embed: "",
                        display_company_branding: 1,
                        company_branding: "",
                        display_logo:1,
                        logo: '',
                        display_author:1,
                        author:"",
                        display_header:1,
                        display_footer:1,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text: 'Submit Plan',
                        element_color: '#2c3e50',
                        element_text_color: '#2C3E50',
                        block_bg_color: '#eff5ff',
                        timer_color: '#f2a31d',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor: '#2C3E50',
                        footer_has_facebook: 1,
                        footer_facebook: '',
                        footer_has_twitter: 1,
                        footer_twitter: '',
                        footer_has_instagram: 1,
                        footer_instagram: '',
                        footer_has_linkedin: 0,
                        footer_has_youtube: 0,
                        footer_linkedin: '',
                        footer_youtube: '',
                        footer_company: '',
                        footer_terms: '',
                        footer_policy: '',
                        thankyou_bgcolor: '#edebeb',
                        thankyou_textcolor: '#2c3e50',
                    },
                    categories: [],
                },
                editWorkoutName: false,
                addNewBlock: false,
                preview: true,
                blockList: [],
                addSmartBlock: false,
                type: false,
                editBlockName: false,
                selectBlockIndex: 0,
                blockTypes: [
                    'Warm-up',
                    'Hypertrophy',
                    'Strength',
                    'Power',
                    'Tabata',
                    'HIIT',
                    'WOD',
                    'Cardio',
                    'Recovery',
                    'Mind',
                    'Smart Block',
                ],
                selectedExercise: {},
                showVideo: false,
                selectedWorkoutBlock: {},
                editBlockSetting: false,
                lastWorkoutBlockId: -1,
                editBlockExercise: false,
                workoutStep: 2,
                moment,
                env: {},
                reminderTab: 'reminders',
                clientReminderTab: 'client',
                reminderEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                notificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                reminderPreviewType: 'email',
                previewReminder: false,
                headerTab: 'logo',
                fullPreview: false,
                messageSmsText: '',
                notificationSmsText: '',
                reminder_sms_media: [],
                notification_sms_media: [],
                previewPage: 'plan',
                switchStep: true,
                lastStep: 0,
                renameWorkoutLoader: false,
                deleteExerciseLoader: false,
                is_private: 0,
                toggleAll: 1,
                selectedBlockForm: {},
                toggleBlockConfirmation: false,
                isBlockDecidingSoundMuted: false,
                whiteLabel: {},
                has_optin_phone: 0,
                loadUnits: {
                    mode: 'single',
                    value: 'lbs',
                    options: [
                        { label: 'Bodyweight', value: 'Bodyweight' },
                        { label: 'Pounds', value: 'lbs' },
                        { label: 'Kilograms', value: 'Kilograms' },
                        { label: '% of 1RM', value: '% of 1RM' },
                        { label: 'Inches', value: 'Inches' },
                        { label: 'Centimeters', value: 'Centimeters' },
                        { label: 'Feet', value: 'Feet' },
                        { label: 'Meters', value: 'Meters' },
                        { label: 'Yards', value: 'Yards' },
                        { label: 'Miles', value: 'Miles' },
                        { label: 'Kilometers', value: 'Kilometers' },
                        { label: 'Metres', value: 'Metres' },
                        { label: 'Light', value: 'Light' },
                        { label: 'Medium', value: 'Medium' },
                        { label: 'Heavy', value: 'Heavy' },
                        { label: 'Easy', value: 'Easy' },
                        { label: 'Moderate', value: 'Moderate' },
                        { label: 'Hard', value: 'Hard' },
                        // { label: 'Slow', value: 'Slow' },
                        // { label: 'Fast', value: 'Fast' },
                    ]
                },
                selectedType: '',
                workRestApplyButtonConfirmation: false,
                workRestApplyButtonConfirmationType: '',
                newCustomExercise: false,
                showExerciseGuide: false,
                disableExerciseGuide: localStorage.getItem('disable_exercise_guide') ? parseInt(localStorage.getItem('disable_exercise_guide')) : 0,
                loadUnitCodes: [
                                    { label: 'Bodyweight', value: 'Bodyweight' },
                                    { label: 'Pounds', value: 'Pounds' },
                                    { label: 'Kilograms', value: 'Kilograms' },
                                    { label: '% of 1RM', value: '% of 1RM' },
                                    { label: 'Inches', value: 'Inches' },
                                    { label: 'Centimeters', value: 'Centimeters' },
                                    { label: 'Feet', value: 'Feet' },
                                    { label: 'Meters', value: 'Meters' },
                                    { label: 'Yards', value: 'Yards' },
                                    { label: 'Miles', value: 'Miles' },
                                    { label: 'Kilometers', value: 'Kilometers' },
                                    { label: 'Metres', value: 'Metres' },
                                    { label: 'Light', value: 'Light' },
                                    { label: 'Medium', value: 'Medium' },
                                    { label: 'Heavy', value: 'Heavy' },
                                    { label: 'Easy', value: 'Easy' },
                                    { label: 'Moderate', value: 'Moderate' },
                                    { label: 'Hard', value: 'Hard' },
                                    // { label: 'Slow', value: 'Slow' },
                                    // { label: 'Fast', value: 'Fast' },
                                ],
                addDropdownWhiteSpace: false,
                createCategory: false,
                categoryForm: {
                    name: '',
                    is_workout_plan: 0,
                }
            };
        },

        props: {
            modelValue: Boolean,
            refreshAsset: {
                type: Function,
                default: () => {}
            },
        },

        emits: ['update:modelValue'],

        components: {
            Draggable,
            Field,
            Form,
            ErrorMessage,
            WorkoutWizardExercise,
            AddSmartBlock,
            EditBlockExercise,
            Swiper,
            SwiperSlide,
            VueTagsInput,
            PlanWorkoutPreview,
            PreviewWorkout,
            WorkoutReminderPreview,
            NewExercise
        },

        computed: {
            ...mapState({
                workoutBlocks: state => state.workoutModule.workoutBlocks,
                selectedWorkout: state => state.workoutModule.selectedWorkout,
                workoutUpdateLoader: state => state.workoutModule.workoutUpdateLoader,
                duplicateBlockLoader: state => state.workoutModule.workoutBlockDuplicateLoader,
                createBlockLoader: state => state.workoutModule.createBlockLoader,
                smartBlockLoader: state => state.workoutModule.workoutSmartBlockLoade,
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                timezones: state => state.commonModule.timezones,
                rewardDetail: state => state.rewardModule.reward,
                defaultWorkoutForm: state => state.workoutModule.defaultWorkoutForm,
                updateBlockLoader: state => state.workoutModule.updateBlockLoader,
                categorySaveLoader: state => state.workoutModule.categorySaveLoader,
                workoutCategories: state => state.workoutModule.workoutCategories,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),

            activeWorkout () {
                const workout  = this.selectedWorkout;
                workout.blocks = this.blockList;

                return workout;
            }
        },

        watch: {
            workoutBlocks (block) {
                const vm = this;

                vm.blockList = block && block.length ? JSON.parse(JSON.stringify(block)) : [];

                if (vm.blockList && vm.blockList.length) {
                    vm.selectedWorkoutBlock = vm.blockList[vm.selectBlockIndex];
                } else {
                    vm.selectBlockIndex     = 0;
                    vm.selectedWorkoutBlock = {};
                }
            },

            modelValue (val) {
                const vm = this;

                vm.toggleScrollBar(val);

                if (val) {
                    vm.blockList          = [];
                    vm.lastWorkoutBlockId = -1;
                    vm.selectBlockIndex   = 0;
                    vm.switchStep         = true;

                    vm.resetForm();

                    if (vm.rewardDetail && !Object.keys(vm.rewardDetail).length) {
                        vm.getReward();
                    }

                    vm.getWorkoutCategories({ is_workout_plan: 0 });
                }

                vm.editWorkoutName    = false;

                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'form.reminder.sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderTab         = 'reminders';
                    vm.clientReminderTab   = 'client';
                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            'form.reminder.notification_sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderTab         = 'admin';
                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            reminderTab (tab) {
                const vm = this;

                if (tab == 'reminders') {
                    vm.reminderPreviewType = vm.form.reminder.sending_method == 2 ? 'sms' : 'email';
                    vm.messageSmsText      = vm.form.reminder && vm.form.reminder.media ? vm.form.reminder.sms_message + '\n\n' + vm.form.reminder.media : '';
                } else if (tab == 'admin') {
                    vm.reminderPreviewType = vm.form.reminder.notification_sending_method == 2 ? 'sms' : 'email';
                    vm.notificationSmsText = vm.form.reminder && vm.form.reminder.notification_media ? vm.form.reminder.notification_sms_message + '\n\n' + vm.form.reminder.notification_media : '';
                }
            },

            clientReminderTab (val) {
                const vm = this;

                vm.reminderPreviewType = vm.form.reminder.sending_method == 2 ? 'sms' : 'email';
                vm.messageSmsText      = vm.form.reminder && vm.form.reminder.media ? vm.form.reminder.sms_message + '\n\n' + vm.form.reminder.media : '';
            },

            reminder_sms_media (media) {
                const vm = this;

                vm.form.reminder.media = media;
            },

            notification_sms_media (media) {
                const vm = this;

                vm.form.reminder.notification_media = media;
            },

            workoutStep (val) {
                const vm = this;

                vm.toggleAll = 1;
            },

            fullPreview (val) {
                const vm = this;

                if (val) {
                    document.body.classList.add('modal-open');

                    const button = document.getElementById('workout-save-button');

                    if (button) {
                        vm.switchStep   = false;
                        vm.closeWindow  = false;
                        button.click();
                    }
                }

                vm.toggleGistChatBox();
            },

            'form.name' (name) {
                const vm = this;

                vm.addVariableToReservedWords({ name: 'workout_name', value: name })
            },

            editBlockExercise (val) {
                const vm = this;
                if(val){
                    vm.closeEditDropdown();
                    vm.closeEditRest();
                }
            },

            newCustomExercise (val) {
                const vm = this;

                if (!val) {
                    vm.getWorkoutBlocks(vm.form.id);
                }
            },
        },

        mounted () {
            const vm = this;

            if (!vm.timezones.length) {
                vm.getTimezones();
            }

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted () {
            const vm = this;

            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions ({
                updateWorkout: 'workoutModule/updateWorkout',
                createNewBlock: 'workoutModule/createNewBlock',
                getWorkoutBlocks: 'workoutModule/getWorkoutBlocks',
                addBlockExercise: 'workoutModule/addBlockExercise',
                deleteWorkoutBlock: 'workoutModule/deleteWorkoutBlock',
                duplicateWorkoutBlock: 'workoutModule/duplicateWorkoutBlock',
                updateWorkoutBlock: 'workoutModule/updateWorkoutBlock',
                createSmartBlock: 'workoutModule/createSmartBlock',
                deleteBlockExercise: 'workoutModule/deleteBlockExercise',
                sortWorkoutBlocks: 'workoutModule/sortWorkoutBlocks',
                sortBlocksExercise: 'workoutModule/sortBlocksExercise',
                getTimezones: 'commonModule/getTimezones',
                getReward: 'rewardModule/getReward',
                renameWorkout: 'workoutModule/renameWorkout',
                updateWorkoutBlockExercise: 'workoutModule/updateWorkoutBlockExercise',
                duplicateWorkoutBlockExercise: 'workoutModule/duplicateWorkoutBlockExercise',
                getWorkoutCategories: 'workoutModule/getWorkoutCategories',
                createWorkoutCategory: 'workoutModule/createWorkoutCategory',
            }),

            ...mapMutations({
                addVariableToReservedWords: 'commonModule/ADD_VARIABLE_TO_RESERVED_WORDS'
            }),

            closeModal () {
                const vm = this;

                vm.refreshAsset();
                vm.$emit('update:modelValue', false);
            },

            closeBlockTypes () {
                const vm = this;

                vm.addNewBlock = false;
            },

            handleCreateWorkoutBlock (name) {
                const vm       = this;
                let block      = {};

                vm.addNewBlock = false;

                if (name == 'Warm-up') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 2,
                                    default_perform_type: 2,
                                    circuit_settings: {
                                        round: 1,
                                        amrap: 0,
                                        work_min: 1,
                                        work_sec: 0,
                                        rest_min: 0,
                                        rest_sec: 20,
                                        has_duration: 1,
                                        block_duration_min: 1,
                                        block_duration_sec: 0,
                                    },
                                    work_interval_type: 1,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 1,
                                };
                } else if (name == 'Tabata') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 2,
                                    default_perform_type: 2,
                                    circuit_settings: {
                                        round: 8,
                                        amrap: 0,
                                        work_min: 0,
                                        work_sec: 10,
                                        rest_min: 0,
                                        rest_sec: 10,
                                        has_duration: 1,
                                        block_duration_min: 0,
                                        block_duration_sec: 20,
                                    },
                                    work_interval_type: 2,
                                    rest_interval_type: 2,
                                    block_interval_type: 2,
                                    block_type: 5,
                                };
                } else if (name == 'HIIT') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 2,
                                    default_perform_type: 2,
                                    circuit_settings: {
                                        round: 3,
                                        amrap: 0,
                                        work_min: 1,
                                        work_sec: 0,
                                        rest_min: 1,
                                        rest_sec: 0,
                                        has_duration: 1,
                                        block_duration_min: 1,
                                        block_duration_sec: 0,
                                    },
                                    work_interval_type: 2,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 6,
                                };
                } else if (name == 'WOD') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 2,
                                    default_perform_type: 2,
                                    circuit_settings: {
                                        round: 3,
                                        amrap: 1,
                                        work_min: 0,
                                        work_sec: 0,
                                        rest_min: 0,
                                        rest_sec: 0,
                                        has_duration: 1,
                                        block_duration_min: 20,
                                        block_duration_sec: 0,
                                    },
                                    work_interval_type: 1,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 7,
                                };
                } else if (name == 'Cardio') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 2,
                                    default_perform_type: 2,
                                    circuit_settings: {
                                        round: 1,
                                        amrap: 0,
                                        work_min: 5,
                                        work_sec: 0,
                                        rest_min: 5,
                                        rest_sec: 0,
                                        has_duration: 1,
                                        block_duration_min: 20,
                                        block_duration_sec: 0,
                                    },
                                    work_interval_type: 2,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 8,
                                };
                } else if (name == 'Recovery') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 2,
                                    default_perform_type: 2,
                                    circuit_settings: {
                                        round: 1,
                                        amrap: 0,
                                        work_min: 0,
                                        work_sec: 10,
                                        rest_min: 0,
                                        rest_sec: 10,
                                        has_duration: 1,
                                        block_duration_min: 1,
                                        block_duration_sec: 0,
                                    },
                                    work_interval_type: 1,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 9,
                                };
                } else if (name == 'Mind') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 2,
                                    default_perform_type: 2,
                                    circuit_settings: {
                                        round: 1,
                                        amrap: 0,
                                        work_min: 1,
                                        work_sec: 0,
                                        rest_min: 1,
                                        rest_sec: 0,
                                        has_duration: 1,
                                        block_duration_min: 5,
                                        block_duration_sec: 0,
                                    },
                                    work_interval_type: 1,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 10,
                                };
                } else if (name == 'Hypertrophy') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 1,
                                    default_perform_type: 1,
                                    circuit_settings: {
                                        round: 1,
                                        amrap: 0,
                                        work_min: 0,
                                        work_sec: 30,
                                        rest_min: 1,
                                        rest_sec: 0,
                                        has_duration: 1,
                                        block_duration_min: 0,
                                        block_duration_sec: 30,
                                    },
                                    work_interval_type: 1,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 2,
                                };
                } else if (name == 'Strength') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 1,
                                    default_perform_type: 1,
                                    circuit_settings: {
                                        round: 1,
                                        amrap: 0,
                                        work_min: 0,
                                        work_sec: 30,
                                        rest_min: 1,
                                        rest_sec: 30,
                                        has_duration: 1,
                                        block_duration_min: 0,
                                        block_duration_sec: 30,
                                    },
                                    work_interval_type: 1,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 3,
                                };
                } else if (name == 'Power') {
                    block = {
                                    name,
                                    workout_id: vm.form.id,
                                    is_smart_block: 0,
                                    smart_block_name: name,
                                    perform_type: 1,
                                    default_perform_type: 1,
                                    circuit_settings: {
                                        round: 1,
                                        amrap: 0,
                                        work_min: 0,
                                        work_sec: 30,
                                        rest_min: 2,
                                        rest_sec: 0,
                                        has_duration: 1,
                                        block_duration_min: 0,
                                        block_duration_sec: 30,
                                    },
                                    work_interval_type: 1,
                                    rest_interval_type: 2,
                                    block_interval_type: 1,
                                    block_type: 4,
                                };
                } else if (name == 'Smart Block') {
                    vm.addSmartBlock = true;
                }

                if (name != 'Smart Block') {
                    vm.selectedBlockForm = block;
                    vm.toggleBlockConfirmation = true;
                }
            },

            handleBlockTypeConfirmation () {
                const vm =  this;

                vm.handleCreateNewBlock(vm.selectedBlockForm);
            },

            addToBlock (ids) {
                const vm = this;

                if (vm.blockList && vm.blockList.length) {
                    const form = {
                                    workout_id: vm.form.id,
                                    exercise_ids: ids,
                                    workout_block_id: vm.selectedWorkoutBlock.id,
                                };

                    vm.addBlockExercise(form).then((result) => {
                        if (result) {
                            vm.getWorkoutBlocks(vm.form.id);
                        }
                    });
                } else {
                    Swal.fire(Helper.swalWarningOptions('Oops!', `You do not have any workout blocks. Please add the block where the exercise will be added.`));
                }
            },

            handleSubmitForm () {
                const vm = this;

                const workoutForm = vm.$refs['workout-wizard-form'];

                if (workoutForm) {
                    workoutForm.validate().then((result) => {
                        if (result.valid) {
                            const params = vm.form;

                            params.reminder.email_subject                        = vm.reminderEmail.subject;
                            params.reminder.email_message                        = vm.reminderEmail.email;
                            params.reminder.show_signature                       = vm.reminderEmail.is_signature;
                            params.reminder.signature_id                         = vm.reminderEmail.signature_id;
                            params.reminder.show_email_logo                      = vm.reminderEmail.show_email_logo;
                            params.reminder.logo_image                           = vm.reminderEmail.email_logo;
                            params.reminder.notification_email_subject           = vm.notificationEmail.subject;
                            params.reminder.notification_email_message           = vm.notificationEmail.email;
                            params.reminder.notification_show_signature          = vm.notificationEmail.is_signature;
                            params.reminder.notification_signature_id            = vm.notificationEmail.signature_id;
                            params.reminder.show_notification_email_logo         = vm.notificationEmail.show_email_logo;
                            params.reminder.notification_logo_image              = vm.notificationEmail.email_logo;
                            params.reminder.email_button_bg_color                = vm.reminderEmail.email_button_bg_color;
                            params.reminder.email_button_text_color              = vm.reminderEmail.email_button_text_color;
                            params.reminder.notification_email_button_bg_color   = vm.notificationEmail.email_button_bg_color;
                            params.reminder.notification_email_button_text_color = vm.notificationEmail.email_button_text_color;

                            params.setFieldError = workoutForm.setFieldError;

                            vm.updateWorkout(params).then((result) => {
                                if (result && vm.workoutStep < 5) {
                                    if (vm.switchStep) {
                                        vm.workoutStep = vm.workoutStep + 1;
                                        vm.lastStep    = vm.workoutStep;
                                    }
                                } else if (result && vm.workoutStep == 5) {
                                    if (vm.switchStep) {
                                        vm.closeModal();
                                    }
                                }

                                vm.switchStep = true;
                            });
                        }
                    });
                }
            },

            handleRenameWorkoutName () {
                const vm = this;

                const renameForm = vm.$refs['rename-workout-form'];

                renameForm.validate().then((result) => {
                    if (result.valid) {
                        vm.handleRenameWorkout(renameForm);
                    }
                });
            },

            handleRenameWorkout (form) {
                const vm = this;

                vm.renameWorkoutLoader  = true
                vm.form.setFieldError   = form.setFieldError;

                vm.renameWorkout(vm.form).then((result) => {
                    if (result) {
                        vm.editWorkoutName = false;
                    }

                    vm.renameWorkoutLoader = false;

                    const wizardForm = vm.$refs['workout-wizard-form'];
                    wizardForm.setFieldError('name', '');
                });
            },

            handleCreateNewBlock (block) {
                const vm = this;

                if (block.perform_type == 2) {
                    block.work_interval_type = 2;
                    block.rest_interval_type = 2;
                    block.is_block_voice     = 1;
                    block.is_round_voice     = 1;
                    block.is_exercise_voice  = 1;
                    block.is_finish_voice    = 1;
                }

                vm.createNewBlock(block).then((result) => {
                    if (result) {
                        vm.getWorkoutBlocks(vm.form.id);
                        vm.toggleBlockConfirmation = false;
                        vm.selectedBlockForm = {};
                    }
                });
            },

            resetForm () {
                const vm = this;

                vm.workoutStep = 2;

                setTimeout(() => {
                    vm.reminderTab       = 'reminders';
                    vm.clientReminderTab = 'client';
                }, 2000);

                if (vm.selectedWorkout.id) {
                    setTimeout(() => {
                        vm.form = JSON.parse(JSON.stringify(vm.selectedWorkout));
                        vm.form.reminder.notification_sending_method = parseInt(vm.form.reminder.notification_sending_method);
                        vm.form.categories = vm.selectedWorkout.categories && vm.selectedWorkout.categories.length ? vm.selectedWorkout.categories.map(category => category.category_id) : [];

                        if (vm.form.public_settings && vm.form.public_settings.explainer_video_type === undefined) {
                            vm.form.public_settings.explainer_video_type = 0;
                            vm.form.public_settings.explainer_embed      = '';
                        }

                        if (vm.form.reminder.notification_sending_method == undefined) {
                            vm.form.reminder.has_notification             = 0;
                            vm.form.reminder.notification_sending_method  = 1;
                            vm.form.reminder.notification_email_subject   = vm.getDefaultMessage('email-subject', 'notification-reminder');
                            vm.form.reminder.notification_email_message   = vm.getDefaultMessage('email-content', 'notification-reminder');
                            vm.form.reminder.notification_sms_message     = vm.getDefaultMessage('sms-content', 'notification-reminder');
                            vm.form.reminder.show_notification_email_logo = true;
                            vm.form.reminder.notification_logo_image      = '';
                            vm.form.reminder.notification_media           = [];
                            vm.form.reminder.notification_email_recipient = [vm.user.email];
                            vm.form.reminder.notification_sms_recipient   = [vm.user.sms_notification_number];
                        } else if (typeof vm.form.reminder.notification_email_recipient == 'string') {
                            vm.form.reminder.notification_email_recipient = [vm.user.email];
                            vm.form.reminder.notification_sms_recipient   = [vm.user.sms_notification_number];
                        }

                        if (vm.form.reminder.show_notification_signature == undefined) {
                            vm.form.reminder.show_notification_signature = 0;
                            vm.form.reminder.notification_signature_id   = 0;
                        }

                        if (vm.form.public_settings.footer_has_facebook == undefined) {
                            vm.form.public_settings.footer_has_facebook  = 1;
                            vm.form.public_settings.footer_has_twitter   = 1;
                            vm.form.public_settings.footer_has_instagram = 1;
                        }

                        if (vm.form.public_settings.seo_title == undefined) {
                            vm.form.public_settings.seo_title       = 'Try This New Workout From {{company_name}}';
                            vm.form.public_settings.seo_keyword     = 'Workouts';
                            vm.form.public_settings.seo_desc        = `This workout is a quick and easy way to stay on track with your fitness goals.`;
                            vm.form.public_settings.seo_index       = 0;
                            vm.form.public_settings.seo_share_title = 'Try This New Workout From {{company_name}}';
                            vm.form.public_settings.seo_share_desc  = `This workout is a quick and easy way to stay on track with your fitness goals.`;
                            vm.form.public_settings.seo_thumb       = '';
                            vm.form.public_settings.seo_fav         = '';
                        }

                        if (vm.form.public_settings.thankyou_bgcolor == undefined) {
                            vm.form.public_settings.thankyou_bgcolor   = '#edebeb';
                            vm.form.public_settings.thankyou_textcolor = '#2c3e50';
                        }

                        if (vm.form.reminder.email_button_bg_color == undefined) {
                            vm.form.reminder.email_button_bg_color                = '#2c3e50';
                            vm.form.reminder.email_button_text_color              = '#FFF';
                            vm.form.reminder.notification_email_button_bg_color   = '#2c3e50';
                            vm.form.reminder.notification_email_button_text_color = '#FFF';
                        }

                        vm.reminderEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.email_subject,
                            email: vm.form.reminder.email_message,
                            is_email_logo: vm.form.reminder.show_email_logo ? 1 : 0,
                            is_signature: vm.form.reminder.show_signature ? 1 : 0,
                            signature_id: vm.form.reminder.signature_id,
                            show_email_logo: vm.form.reminder.show_email_logo ? 1 : 0,
                            email_logo: vm.form.reminder.logo_image,
                            email_button_bg_color: vm.form.reminder.email_button_bg_color ? vm.form.reminder.email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.email_button_text_color ? vm.form.reminder.email_button_text_color : '#FFF',
                            module_id: vm.selectedWorkout.id,
                        };

                        vm.notificationEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.notification_email_subject,
                            email: vm.form.reminder.notification_email_message,
                            is_email_logo: vm.form.reminder.show_notification_email_logo ? 1 : 0,
                            is_signature: vm.form.reminder.notification_show_signature ? 1 : 0,
                            signature_id: vm.form.reminder.notification_signature_id,
                            show_email_logo: vm.form.reminder.show_notification_email_logo ? 1 : 0,
                            email_logo: vm.form.reminder.notification_logo_image,
                            email_button_bg_color: vm.form.reminder.notification_email_button_bg_color ? vm.form.reminder.notification_email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.notification_email_button_text_color ? vm.form.reminder.notification_email_button_text_color : '#FFF',
                            module_id: vm.selectedWorkout.id,
                        };

                        vm.messageSmsText      = vm.selectedWorkout.reminder && vm.selectedWorkout.reminder.sms_message ? vm.selectedWorkout.reminder.sms_message : '';
                        vm.notificationSmsText = vm.selectedWorkout.reminder && vm.selectedWorkout.reminder.notification_sms_message ? vm.selectedWorkout.reminder.notification_sms_message : '';

                        vm.getWorkoutBlocks(vm.form.id);
                    }, 100);
                }

                vm.categoryForm = {
                    name: '',
                    is_workout_plan: 0,
                };

                vm.createCategory = false;
            },

            handleDeleteWorkoutBlock (id) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this workout block`, 'Delete');

                options.preConfirm = function () {
                                        const params = { id, workout_id: vm.form.id }

                                        return vm.deleteWorkoutBlock(params).then((result) => {
                                            if (result) {
                                                vm.getWorkoutBlocks(vm.form.id);
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            handleDuplicateWorkoutBlock (id) {
                const vm = this;

                vm.duplicateWorkoutBlock({ id, workout_id: vm.form.id }).then((result) => {
                    if (result) {
                        vm.getWorkoutBlocks(vm.form.id);
                    }
                });
            },

            handleRenameWorkoutBlockName () {
                const vm =  this;

                vm.updateWorkoutBlock(vm.selectedWorkoutBlock).then((result) => {
                    if (result) {
                        vm.getWorkoutBlocks(vm.form.id);
                        vm.editBlockName = false;
                    }
                });
            },

            handleUpdateWorkoutBlock (timeUpdateIn = null) {
                const vm = this;

                const workoutForm = vm.$refs['workout-wizard-form'];

                if (workoutForm) {
                    workoutForm.validate().then((result) => {
                        if (result.valid) {
                            let form = JSON.parse(JSON.stringify(vm.selectedWorkoutBlock));

                            if (timeUpdateIn) {
                                form.is_time_update = 1;
                                form.time_update_in = timeUpdateIn;
                            }

                            vm.updateWorkoutBlock(form).then((result) => {
                                if (result) {
                                    vm.getWorkoutBlocks(vm.form.id);
                                    vm.workRestApplyButtonConfirmation = false;
                                }
                            });
                        }
                    });
                }
            },

            toggleBlockSetting (id) {
                const vm = this;

                setTimeout(function () {
                    vm.editBlockSetting   = true;
                    vm.lastWorkoutBlockId = vm.lastWorkoutBlockId == id ? 0 : id;

                    setTimeout(function () {
                        vm.editBlockSetting = false;
                    }, 100);
                }, 100);
            },

            handleBlockSettingOutsideClick (event) {
                const vm = this;

                if (vm.checkIfParentHasClass(event.target, 'block_setting') == false) {
                    vm.lastWorkoutBlockId = 0;
                }
            },

            handleUpdateSmartBlock () {
                const vm = this;

                const workoutForm = vm.$refs['workout-wizard-form'];

                if (workoutForm) {
                    workoutForm.validate().then((result) => {
                        if (result.valid && vm.selectedWorkoutBlock.is_smart_block) {
                            const message = vm.selectedWorkoutBlock.smart_block_id ? 'This workout block is also a smart workout block, do you want to create another smart workout block?' : 'You want make this workout block as smart workout block.';
                            const options = Helper.swalConfirmOptions('Are you sure?', message, 'Yes', false);

                            Swal.fire(options).then(({ isConfirmed }) => {
                                if (isConfirmed) {
                                    const parmas = { id: vm.selectedWorkoutBlock.id, workout_id: vm.selectedWorkout.id, smart_block_name: vm.selectedWorkoutBlock.smart_block_name };

                                    vm.createSmartBlock(parmas).then((result) => {
                                        if (result) {
                                            vm.handleUpdateWorkoutBlock();
                                        }
                                    })
                                }
                            });
                        }
                    });
                }
            },

            handleDeleteBlockExercise (exercise) {
                const vm = this;

                // const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this workout block exercise`, 'Delete');
                //
                // options.preConfirm = function () {
                //                         const params = { id: exercise.id, workout_id: exercise.workout_id, workout_block_id: exercise.workout_block_id };
                //
                //                         return vm.deleteBlockExercise(params).then((result) => {
                //                             if (result) {
                //                                 vm.getWorkoutBlocks(vm.form.id);
                //                             }
                //                         });
                //                     };
                //
                // Swal.fire(options);

                const params            = { id: exercise.id, workout_id: exercise.workout_id, workout_block_id: exercise.workout_block_id };
                vm.deleteExerciseLoader = true;

                return vm.deleteBlockExercise(params).then((result) => {
                    if (result) {
                        vm.getWorkoutBlocks(vm.form.id);
                    }

                    vm.deleteExerciseLoader = false;
                });
            },

            handleSortWorkoutBlocks () {
                const vm = this;
                const ids = vm.blockList.map(({id}) => { return id });

                vm.sortWorkoutBlocks({ ids, workout_id: vm.form.id });
            },

            handleSortBlocksExercise (blockIndex) {
                const vm  = this;
                // const ids = vm.selectedWorkoutBlock.exercises.map(({id}) => { return id });
                const ids = vm.blockList[blockIndex].exercises.map(({id}) => { return id });

                vm.sortBlocksExercise({ ids, workout_id: vm.form.id, workout_block_id: vm.blockList[blockIndex].id });
            },

            handleEditBlockExercise (exercise) {
                const vm = this;

                vm.selectedExercise = exercise;

                setTimeout(function () {
                    vm.editBlockExercise = true;
                }, 100);
            },

            handleUpdateBlockExercise (exercise) {
                const vm = this;
                const form = vm.$refs['workout-block-exercise-setting-form'];

                if (form) {
                    form.validate().then((result) => {
                        if (result.valid) {
                            vm.selectedExercise = exercise;

                            vm.updateWorkoutBlockExercise(exercise).then((result) => {
                                if (result) {
                                    vm.getWorkoutBlocks(vm.form.id);
                                    vm.closeEditDropdown();
                                    vm.closeEditRest();
                                }
                            });
                        }
                    });
                }
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },

            toggleStep (step) {
                const vm = this;

                if (vm.isNew) {
                    if (step <= vm.lastStep) {
                        vm.workoutStep = step;
                    }
                } else {
                    vm.workoutStep = step;
                }
            },

            generateDefaultMessage () {
                const vm = this;

                vm.form.reminder.email_subject  = '';
                vm.form.reminder.email_message  = '';
                vm.form.reminder.sms_message    = '';
                vm.form.reminder.notification_email_subject  = '';
                vm.form.reminder.notification_email_message  = '';
                vm.form.reminder.notification_sms_message    = '';
                vm.form.reminder.email_subject               = vm.getDefaultMessage('email-subject', 'reminder');
                vm.form.reminder.email_message               = vm.getDefaultMessage('email-content', 'reminder');
                vm.form.reminder.sms_message                 = vm.getDefaultMessage('sms-content', 'reminder');
                vm.form.reminder.notification_email_subject  = vm.getDefaultMessage('email-subject', 'notification-reminder');
                vm.form.reminder.notification_email_message  = vm.getDefaultMessage('email-content', 'notification-reminder');
                vm.form.reminder.notification_sms_message    = vm.getDefaultMessage('sms-content', 'notification-reminder');
            },

            getDefaultMessage (type, section) {
                if (type == 'email-subject' && section == 'reminder') {
                    return "You have been assigned a new workout!";
                } else if (type == 'email-content' && section == 'reminder') {
                    let email_message = "<p>Hi {{first_name}},</p>";
                        email_message += "<p>{{company_owner_first_name}} from {{company_name}} has assigned you a new workout.</p>";
                        email_message += "<p>Workout Name: {{workout_name}}</p>";
                        email_message += "<p>This is also visible in your client portal.</p>";
                        email_message += "<p>{{workout_button}}</p>";
                        email_message += "<p>You can always chat with your coach via your client portal if you have any questions.</p>";
                        email_message += "<p>Cheers!</p>";
                        email_message += "<p>Team {{company_name}}</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'reminder') {
                    let sms_message = "{{company_owner_first_name}} from {{company_name}} has assigned you a new workout.\r\n";
                        sms_message += "Workout Name: {{workout_name}}\r\n";
                        sms_message += "This is also visible in your client portal.\r\n";
                        sms_message += "{{workout_link}}\r\n";
                        sms_message += "You can always chat with your coach via your client portal if you have any questions.\r\n";
                        sms_message += "Cheers!\r\n";
                        sms_message += "Team {{company_name}}";

                    return sms_message;
                } else if (type == 'email-subject' && section == 'notification-reminder') {
                    return "Workout completed by {{full_name}}.";
                } else if (type == 'email-content' && section == 'notification-reminder') {
                    let email_message = "<p>{{full_name}} has completed a workout.</p>";
                        email_message += "<p><b>Workout Name:</b> {{workout_name}}</p>";
                        email_message += "<p>Client email: {{email}}</p>";
                        email_message += "<p>Click to view their feedback and details</p>";
                        email_message += "<p>{{workout_response_button}}</p>";
                        email_message += "<p>You can always chat with your client via your message center.</p>";
                        email_message += "<p>Cheers!</p>";
                        email_message += "<p>Thrive Coach</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'notification-reminder') {
                    let sms_message = "{{full_name}} has completed a workout.\r\n";
                        sms_message += "Workout Name: {{workout_name}}.\r\n";
                        sms_message += "Client email: {{email}}}.\r\n";
                        sms_message += "Click to view their feedback and details.\r\n";
                        sms_message += "{{workout_response_link}}\r\n";
                        sms_message += "You can always chat with your client via your message center\r\n";
                        sms_message += "Cheers!\r\n";
                        sms_message += "Thrive Coach\r\n";

                    return sms_message;
                }

                return '';
            },

            handleReminderDefaultEmail () {
                const vm = this;

                vm.form.reminder.email_subject  = vm.getDefaultMessage('email-subject', 'reminder');
                vm.form.reminder.email_message  = vm.getDefaultMessage('email-content', 'reminder');

                vm.reminderEmail.subject  = vm.form.reminder.email_subject;
                vm.reminderEmail.email    = vm.form.reminder.email_message;
            },

            handleReminderDefaultSms () {
                const vm = this;

                vm.form.reminder.sms_message    = vm.getDefaultMessage('sms-content', 'reminder');
            },

            handleNotificationDefaultEmail () {
                const vm = this;

                vm.form.reminder.notification_email_subject  = vm.getDefaultMessage('email-subject', 'notification-reminder');
                vm.form.reminder.notification_email_message  = vm.getDefaultMessage('email-content', 'notification-reminder');

                vm.notificationEmail.subject  = vm.form.reminder.notification_email_subject;
                vm.notificationEmail.email    = vm.form.reminder.notification_email_message;
            },

            handleNotificationDefaultSms () {
                const vm = this;

                vm.form.reminder.notification_sms_message    = vm.getDefaultMessage('sms-content', 'notification-reminder');
            },

            handleNotificationEmailRecipient (recipient) {
                const vm = this;

                vm.form.reminder.notification_email_recipient = recipient;
            },

            handleNotificationSMSRecipient (recipient) {
                const vm = this;

                vm.form.reminder.notification_sms_recipient = recipient;
            },

            goTo (refName) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                setTimeout(function () {
                    if ( vm.workoutStep != 1 && vm.workoutStep != 2 ) {
                        setTimeout(function () {
                            let element = vm.$refs[refName];

                            setTimeout(function() {
                                if (element) {
                                    let top = element.offsetTop;
                                }
                                // document.querySelector('.preview_content .content_area').scrollTo(0, top);
                            }, 100);
                        }, 10);
                    } else {
                        // vm.$refs['preview-content'].goTo(refName);
                    }
                }, 10);
            },

            setting (refName) {
                const vm = this;

                const section = ['cvrsetting', 'ftrsetting', 'subbtnsetting', 'formsetting', 'thankyousetting'];

                if (section.includes(refName)) {
                    vm.workoutStep = refName === 'formsetting' ? 2 : 3;

                    setTimeout(function () {
                        let element = vm.$refs[refName];

                        setTimeout(function () {
                            if (element) {
                                let top = element.offsetTop;
                                // document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                            }
                        }, 100);
                    }, 100);
                }
            },

            updateSmsText () {
                const vm = this;

                const messageSms      = vm.$refs['message-sms-component'];
                const notificationSms = vm.$refs['notification-sms-component'];

                if (messageSms) {
                    vm.messageSmsText = messageSms.smsMedia && messageSms.smsMedia.media && messageSms.smsMedia.media[0] ? vm.form.reminder.sms_message + '\n\n' + messageSms.smsMedia.media[0].toString() : vm.form.reminder.sms_message;
                }

                if (notificationSms) {
                    vm.notificationSmsText = notificationSms.smsMedia && notificationSms.smsMedia.media && notificationSms.smsMedia.media[0] ? vm.form.reminder.notification_sms_message + '\n\n' + notificationSms.smsMedia.media[0].toString() : vm.form.reminder.notification_sms_message;
                }
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                });
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 768) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            handleAddOption (ev, blockNumber, blockElement) {
                const targetElm = ev.currentTarget;
                const elm       = targetElm.querySelector('.dropdown_wpr');
                const vm        = this;

                vm.selectBlockIndex     = blockNumber;
                vm.selectedWorkoutBlock = blockElement;

                if (elm.classList.contains('active')) {
                    elm.classList.remove('active');
                } else {
                    vm.closeAddOption();
                    elm.classList.add('active');
                }
            },

            closeAddOption () {
                const elements = document.querySelectorAll('.block_panel .add_item .dropdown_wpr:not(.rest-dropdown-wrapper)');

                elements.forEach((item) => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                });
            },

            blinkAddExercise () {
                const vm = this;
                const el = vm.$refs['workout-wizard-exercise'];

                if (el) {
                    el.showContent          = true;
                    vm.showExerciseGuide    = true;
                    vm.preview              = true;

                    setTimeout(() => {
                        el.showContent = false;
                        vm.showExerciseGuide = false;
                    }, 10000);
                }
            },

            handleEditWorkoutName () {
                const vm           = this;
                vm.editWorkoutName = true;

                setTimeout(()=> {
                    const el = document.getElementById('workout-name');

                    if (el) {
                        el.focus();
                    }
                }, 100)
            },

            handleAutoSaveForm () {
                const vm          = this;
                const saveButton  = document.getElementById('workout-save-button');

                if (saveButton) {
                    vm.switchStep   = false;
                    vm.closeWindow  = false;
                    saveButton.click();
                }
            },

            handleTutorialVideo () {
                const vm = this;

                if (vm.workoutStep == 2) {
                    vm.tutorialVideo = 'https://www.loom.com/embed/e3f62eccee514aa08f7fa6cc859de09b?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
                } else if (vm.workoutStep == 3) {
                    vm.tutorialVideo = 'https://www.loom.com/embed/1312b54cdb6c490290768da87bef3d87?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
                } else if (vm.workoutStep == 4) {
                    vm.tutorialVideo = 'https://www.loom.com/embed/8fb6743bf9ed44a4a967693300807b3d?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
                } else if (vm.workoutStep == 5) {
                    vm.tutorialVideo = 'https://www.loom.com/embed/3aca8fab368a490f94e17f53bf1586fc?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';
                }

                vm.tutorial = true;
            },

            playPauseBlockDecidingSound () {
                const vm = this;

                vm.isBlockDecidingSoundMuted = !vm.isBlockDecidingSoundMuted;

                if (vm.isBlockDecidingSoundMuted) {
                    blockSelectionHelper.play();
                } else {
                    blockSelectionHelper.pause();
                }
            },

            closeDescription (e) {
                const vm = this;

                if (!e.target.classList.contains('multiselect-tag-remove-icon') && !e.target.classList.contains('save_btn')) {
                    vm.showDescription = false;
                }
            },

            toggleDetails (ev) {
                let elm = ev.currentTarget;
                let targetElm = elm.closest('.color_container').querySelector('.content_details');

                if (targetElm.classList.contains('show')) {
                    targetElm.classList.remove('show');
                    elm.innerHTML = 'Open';
                } else {
                    targetElm.classList.add('show');
                    elm.innerHTML = 'Close';
                }
            },

            handleEditTitle (ev, exercise) {
                const vm = this;
                const blockExerciseTitle = ev.currentTarget.closest('.block_panel li');

                vm.closeEditTitle();

                blockExerciseTitle.classList.add('edit_title');
            },

            closeEditTitle (exercise = null) {
                const vm = this;
                const blockExerciseTitleAll = document.querySelectorAll('.block_panel li');

                blockExerciseTitleAll.forEach((item) => {
                    if (item.classList.contains('edit_title')) {
                        item.classList.remove('edit_title');
                    }
                });

                if (exercise) {
                    vm.updateWorkoutBlockExercise(exercise).then((result) => {
                        if (result) {
                            vm.getWorkoutBlocks(vm.form.id);
                        }
                    });
                }
            },

            handleEditRest (id) {
                const vm = this;
                const element = document.getElementById(`rest-dropdown-wrapper-${id}`);

                vm.closeEditRest();

                element.classList.add('active');
            },

            closeEditRest (refresh = false) {
                const vm = this;
                const elements = document.querySelectorAll('.rest-dropdown-wrapper');

                elements.forEach((item) => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                });

                if (refresh == true) {
                    vm.getWorkoutBlocks(vm.form.id);
                }
            },


            handleEdit (ev, type) {
                const vm = this;
                const tabContent = document.querySelector('.global_setting .tabs_content');
                const elm = ev.currentTarget.closest('.short_info');
                const elmDropdown = elm.querySelector('.dropdown_wpr');

                vm.selectedType = type;

                vm.closeEditDropdown();

                if (elm.classList.contains('has_dropdown')) {
                    elm.classList.remove('has_dropdown');
                    elmDropdown.classList.remove('active');
                    vm.addDropdownWhiteSpace = false;
                } else {
                    elm.classList.add('has_dropdown');
                    elmDropdown.classList.add('active');
                    vm.addDropdownWhiteSpace = false;
                }

                const blockElement      = elm.closest('.block_list > li').offsetTop;
                const blockHeight       = elm.closest('.block_list > li').getBoundingClientRect().height;
                const blockHeightSum    = blockElement + blockHeight + 400;
                const screenHeight      = window.innerHeight;

                if (blockHeightSum > screenHeight) {
                    tabContent.scrollTo(0, blockElement + 320);
                }
            },

            closeEditDropdown (refresh = false) {
                const vm = this;
                const elmAll = document.querySelectorAll('.short_info');
                const elmDropdownAll = document.querySelectorAll('.short_info .dropdown_wpr');

                elmAll.forEach((item) => {
                    if (item.classList.contains('has_dropdown')) {
                        item.classList.remove('has_dropdown');
                    }
                });

                elmDropdownAll.forEach((item) => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active');
                    }
                });

                vm.addDropdownWhiteSpace = false;

                if (refresh == true) {
                    vm.getWorkoutBlocks(vm.form.id);
                }
            },

            closeAllDropdown () {
                const vm = this;

                vm.closeEditDropdown();
                vm.closeEditRest();
            },

            handleWorkRestApplyButtonConfirmation (type, block) {
                const vm = this;

                if ((type == 'work' && vm.filterWorkTime(block) == 'variable') || (type == 'rest' && vm.filterRestTime(block) == 'variable rest intervals')) {
                    vm.workRestApplyButtonConfirmationType = type;
                    vm.workRestApplyButtonConfirmation = true;
                } else {
                    vm.handleUpdateWorkoutBlock(type);
                }
            },

            handleUnitChange (element) {
                element.load_unit = 'Bodyweight';
            },

            handleFlipBlockExercise (exercise) {
                const vm = this;

                vm.updateWorkoutBlockExercise(exercise).then((result) => {
                    if (result) {
                        vm.getWorkoutBlocks(vm.form.id);
                    }
                });
            },

            handleDuplicateBlockExercise (exercise) {
                const vm = this;

                vm.duplicateWorkoutBlockExercise(exercise).then((result) => {
                    vm.getWorkoutBlocks(vm.form.id);
                });
            },

            handleDisableExerciseGuide () {
                const vm = this;

                localStorage.setItem('disable_exercise_guide', vm.disableExerciseGuide);
            },

            handleExerciseVariableChange (exercise) {
                const vm = this;

                if (exercise.is_variable) {
                    vm.handleEditBlockExercise(exercise);
                    vm.handleUpdateBlockExercise(exercise);
                }
            },

            filterWorkTime (block) {
                let variableWorkTime = false;
                let text = '';
                const workMinutes = [];
                const workSeconds = [];
                const workReps    = [];

                if (block.exercises) {
                    block.exercises.forEach((exercise) => {
                        if (exercise.measure_type == 2) {
                            workMinutes.push(parseInt(exercise.exercise_minutes));
                            workSeconds.push(parseInt(exercise.exercise_seconds));

                            if (parseInt(exercise.exercise_minutes) !== parseInt(block.circuit_settings.work_min)) {
                                variableWorkTime = true;
                            }

                            if (parseInt(exercise.exercise_seconds) !== parseInt(block.circuit_settings.work_sec)) {
                                variableWorkTime = true;
                            }
                        } else if (exercise.measure_type == 1) {
                            if (exercise.is_failure) {
                                workReps.push(0);
                            } else if (exercise.is_reps_ranges) {
                                workReps.push(exercise.min_reps);
                            } else {
                                workReps.push(exercise.exercise_reps);
                            }
                        }
                    })
                }

                const uniqueMinutes = workMinutes.filter((value, index, array) => array.indexOf(value) === index);
                const uniqueSeconds = workSeconds.filter((value, index, array) => array.indexOf(value) === index);

                if ((uniqueMinutes.length > 1 || uniqueSeconds.length > 1) || workReps.length >= 1) {
                    variableWorkTime = true;
                } else {
                    variableWorkTime = false;
                }

                if (variableWorkTime) {
                    return 'variable';
                }

                if (uniqueMinutes[0]) {
                    text += `${uniqueMinutes[0] ? uniqueMinutes[0] : block.circuit_settings.work_min} min `;
                }

                if (uniqueSeconds[0]) {
                    text += `${uniqueSeconds[0] ? uniqueSeconds[0] : block.circuit_settings.work_sec} sec`;
                }

                return text;
            },

            filterRestTime (block) {
                let variableWorkTime = false;
                let text = '';
                let hasRestTime = false;
                const restMinutes = [];
                const restSeconds = [];

                if (block.exercises) {
                    block.exercises.forEach((exercise) => {
                        restMinutes.push(parseInt(exercise.rest_minutes));
                        restSeconds.push(parseInt(exercise.rest_seconds));

                        if (parseInt(exercise.rest_minutes) !== parseInt(block.circuit_settings.rest_min)) {
                            variableWorkTime = true;
                        }

                        if (parseInt(exercise.rest_seconds) !== parseInt(block.circuit_settings.rest_sec)) {
                            variableWorkTime = true;
                        }

                        if (exercise.is_rest) {
                            hasRestTime = true;
                        }
                    })
                }

                const uniqueMinutes = restMinutes.filter((value, index, array) => array.indexOf(value) === index);
                const uniqueSeconds = restSeconds.filter((value, index, array) => array.indexOf(value) === index);

                if (uniqueMinutes.length > 1 || uniqueSeconds.length > 1) {
                    variableWorkTime = true;
                } else {
                    variableWorkTime = false;
                }

                if (variableWorkTime) {
                    return 'variable rest intervals';
                }

                if (uniqueMinutes[0]) {
                    text += `${uniqueMinutes[0] ? uniqueMinutes[0] : block.circuit_settings.rest_min} min `;
                }

                if (uniqueSeconds[0]) {
                    text += `${uniqueSeconds[0] ? uniqueSeconds[0] : block.circuit_settings.rest_sec} sec `;
                }

                text += 'rest intervals';

                if (!hasRestTime) {
                    text = block.perform_type == 1 ? 'Super Set' : 'No rest'
                }

                return text;
            },

            blockHasWorkTime (block) {
                let hasWorkTime = false;

                if (block.exercises) {
                    block.exercises.forEach((exercise) => {
                        if (exercise.measure_type == 2) {
                            hasWorkTime = true;
                        }
                    })
                }

                return hasWorkTime;
            },

            filterLoadNumber (exercise) {
                if (exercise.load_unit == 'Light' || exercise.load_unit == 'Medium' || exercise.load_unit == 'Heavy' || exercise.load_unit == 'Easy' || exercise.load_unit == 'Moderate' || exercise.load_unit == 'Hard') {
                    return '';
                } else if (exercise.load_number == 0) {
                    return '--';
                }

                return exercise.load_number;
            },

            handleCreateCategory () {
                const vm = this;

                const categoryForm = vm.$refs['category-form'];

                categoryForm.validate().then((result) => {
                    if (result.valid) {
                        vm.categoryForm.setFieldError = categoryForm.setFieldError;

                        vm.createWorkoutCategory(vm.categoryForm).then((result) => {
                            if (result) {
                                vm.createCategory    = false;
                                vm.categoryForm.name = '';
                                vm.getWorkoutCategories({ is_workout_plan: 0 });
                            }
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .global_setting .tabs_content .content_wpr{
        margin-bottom: 200px;
    }
    .workout-wizard-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .progress_item {
        border-radius: 10px;
        max-width: 400px;
        margin: 0 auto;
        border: 1px solid #e0e0e0;
    }

    .progress_item .item_img img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
    }

    .progress_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .progress_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .progress_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .progress_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .step_name {
        display: flex;
        align-items: center;
    }

    .step_name h3 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        padding: 5px 0;
        transition: all 0.3s ease-in-out;
    }

    .step_wpr h3:not(.sub_header) {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }

    .sec_header {
        font-size: 17px;
        line-height: 25px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }

    .track_list {
        padding: 15px;
    }

    .track_list li {
        padding: 0 20px 20px 40px;
        position: relative;
    }

    .track_list li:not(:last-child):after {
        position: absolute;
        content: '';
        left: 14px;
        top: 0;
        bottom: 0;
        border-left: 2px dashed #b0d1ff;
    }

    .step_number {
        position: absolute;
        left: 4px;
        top: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2f7eed;
        border: 3px solid #b0d1ff;
        color: #fff;
        z-index: 1;
        font-size: 9px;
        transition: all 0.3s ease-in-out;
    }

    .track_list li .step_name.active h3 {
        font-size: 15px;
        line-height: 20px;
    }

    .track_list li .step_name.active .step_number {
        font-size: 13px;
        left: 0;
        width: 30px;
        height: 30px;
    }

    .time_listing {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -7px;
    }

    .time_listing li {
        padding: 5px 7px;
        width: 20%;
    }

    .time_listing li .checkbox {
        display: block;
    }

    .selection_box {
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        border: 1px solid #d9d9d9;
        color: #121525;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .time_listing li .checkbox input[type=radio]:checked~.selection_box {
        border: 1px solid #b0d1ff;
        background: rgba(47, 126, 237, 0.05);
        color: #2f7eed;
    }

    .selection_box:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    .day_type {
        display: flex;
        flex-direction: column;
        margin: 0 -15px;
    }

    .day_type li {
        padding: 10px 15px;
        flex: 1 0 50%;
    }

    .day_type li .day_wpr {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    .day_type li .day_wpr label {
        min-width: 50px;
        height: auto;
        background: #fff;
        border-right: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
    }

    .day_type li .day_wpr label span {
        width: 20px;
        height: 20px;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.3);
    }

    .day_type li .day_wpr label span i {
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .day_type li .day_wpr label input[type=radio]:checked~span i {
        transform: scale(1);
    }

    .day_type li .day_wpr h4 {
        font-size: 13px;
        line-height: 30px;
        padding: 7px 15px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .sqr_input {
        width: 30px;
        height: 22px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        text-align: center;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 12px;
        font-weight: 500;
    }

    .date_input {
        width: 140px;
        height: 25px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        padding: 0 5px;
        text-align: left;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 13px;
        font-weight: 500;
    }

    .multiselect.small_box {
        width: 80px;
        min-height: 25px;
        margin: 0 7px;
        font-size: 12px;
    }

    .sqr_input::-webkit-outer-spin-button,
    .sqr_input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .sqr_input[type=number] {
        -moz-appearance: textfield;
    }

    .time_selection {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .time_selection .form_grp {
        padding: 10px;
        width: 50%;
        margin: 0;
        align-items: flex-end;
    }

    .day_sorting {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px;
    }

    .day_sorting li {
        padding: 5px;
        width: 25%;
        min-width: 90px;
    }

    .day_sorting li label {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
    }

    .circumference_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 15px -10px;
    }

    .circumference_list li {
        padding: 10px;
        width: 50%;
    }

    .circumference_list li label {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .circumference_list li label:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .custom_list li {
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 30px;
    }

    .custom_list li h5.category_bar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 500;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        padding: 0 15px;
        column-gap: 15px;
    }

    .category_bar .capsule_btn {
        border-right: 1px solid #d9d9d9;
    }

    .category_bar input {
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }

    .category_bar.active input,
    .category_bar input:focus {
        background: #f7faff;
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        padding: 15px;
    }

    .category_bar .add_btn {
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        padding-right: 15px;
        border-right: 1px solid #d9d9d9;
    }

    .category_bar .add_btn i {
        font-size: 10px !important;
    }

    .category_bar .delete_btn {
        color: #eb1414;
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        cursor: pointer;
    }

    .category_bar .bar_text {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
    }

    .category_bar .bar_text:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-bottom: 1px solid #e9e9e9;
    }

    .category_bar .bar_actions {
        display: flex;
        align-items: center;
        height: 50px;
    }

    .custom_list li h5 span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .custom_list li h5 label {
        padding: 0 15px 0 0;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: auto;
        border-right: 1px solid #d9d9d9;
    }

    .custom_list li h5 label.has {
        color: #2f7eed;
    }

    .custom_list li h5 .edit_btn {
        font-size: 16px;
        color: #2F7FED;
        margin: 5px 0px;
        background: transparent;
        padding: 0px;
    }

    .custom_list li .section_wpr {
        border-top: 1px solid #eaeaea;
        margin: 10px -15px 0 -15px;
        padding: 10px 30px;
    }

    .sub_categories {
        padding: 30px 20px;
        border: 1px solid #eaeaea;
        background: #fafafa;
        border-radius: 6px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .sub_categories h6 {
        font-size: 13px;
        line-height: 18px;
        color: #999;
        font-weight: 400;
        text-align: center;
    }

    .category_bar .quest_title {
        flex: 1 1 auto;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }

    .prog_edit {
        background: #fff;
        padding: 20px 30px 30px 30px;
        border: 1px solid #eaeaea;
        border-radius: 6px;
        margin-top: 10px;
        cursor: auto;
    }

    .prog_edit :deep(.tab_content) {
        height: auto;
    }

    .prog_edit .field_wpr textarea {
        padding: 10px 15px;
        font-size: 13px;
        line-height: 18px;
        height: 100px;
        background: transparent;
        resize: none;
    }

    .grp_wpr {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 20px 0 5px 0;
    }

    .grp_wpr label .box_item {
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }

    .grp_wpr label .box_item i {
        font-size: 15px;
    }

    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }

    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }

    .grp_wpr label input[type="radio"]:checked~.box_item {
        opacity: 1;
    }

    .question-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px 0 5px;
        gap: 20px;
    }

    .question-options label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }

    .question-options label p {
        font-size: 13px;
        line-height: 18px;
        padding-left: 12px;
    }

    .question-options .radio_box {
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 3px;
    }

    .question-options .radio_box .dot {
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .question-options label input[type="radio"]:checked~.radio_box .dot {
        transform: scale(1);
    }


    .modal.add_question .modal_header {
        padding: 0 45px;
    }

    .modal.add_question .modal_body {
        padding: 30px 45px 35px 45px;
        display: block;
    }

    .mcq_list li {
        border: 1px solid #d9d9d9;
        padding: 0 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        padding: 12px 15px 10px 0;
        font-size: 14px;
        line-height: 19px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }

    .col-2 {
        display: flex;
        margin: 0 -15px;
    }

    .col-2 .color_palette {
        flex: 1 0 50%;
        padding: 10px 15px;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 6;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .tab_sec li {
        display: flex;
        align-items: center;
    }

    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .cover_type {
        margin: 15px -35px 15px -35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 35px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    .days {
        display: flex;
        padding: 15px 0;
        gap: 7px;
    }

    .cell .days {
        border-top: 1px solid #E6E6E6;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px;
    }

    .days li {
        padding: 4px 0;
        flex: 0 1 55px;
    }

    .days li label {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 10px 6px 12px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }

    .days li label span {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .days li label .tick_box {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #5a5a5a;
        position: relative;
        margin-top: 10px;
    }

    .days li label .tick_box:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .days li label input {
        margin-right: 7px;
        opacity: 0;
    }

    .days li label.active {
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid #b0d1ff;
    }

    .days li label.active .tick_box:after {
        transform: scale(1);
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p,
    .cell .sms_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .hourly_info {
        padding: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        background: #fff;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #757575;
        text-align: center;
    }

    .form_grp :deep(.multiselect-single-label span) {
        font-size: 13px;
    }

    :deep(.cell .dashboard_footer) {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        /* background: #fff;
        border: 1px solid #e9e9e9; */
        /* border-radius: 10px; */
        max-width: 400px;
        margin: 0 auto;
        position: relative;
    }
    .info_bar .tutorial_btn{
        position: absolute;
        left: 100%;
        top: 25px;
        padding: 4px 10px;
        background: #fff;
        border-radius: 11px;
        font-size: 11px;
        line-height: 14px;
        color: #2f7eed;
        font-weight: 500;
        margin-left: 5%;
        cursor: pointer;
    }
    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        display: none;
    }

    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }

    .step_bar2 {
        position: relative;
        padding: 20px 15px 10px 15px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }

    .cover-header-with-text {
        display: flex;
    }

    .cover-header-with-text .small-cover-info {
        margin: 0;
        margin-left: 10px;
    }

    .cover-header-with-text div>small {
        font-size: 12px;
    }

    .flex-center {
        display: flex;
        align-items: center;
    }


    /* toggle_section */
    .toggle_section {
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }

    .toggle_section i {
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content {
        position: relative;
    }

    .global_setting .tabs_content .section_content .section_header {
        margin: 10px 0;
    }

    .global_setting .tabs_content .section_content:after {
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right: 20px;
        border-bottom: 1px solid #e9e9e9;
    }

    .global_setting .tabs_content .section_content.show:after,
    .global_setting .tabs_content .section_content:last-of-type:after,
    .global_setting .tabs_content .section_content.open_area:after {
        border: 0;
    }

    .global_setting .tabs_content .section_content .toggle_content {
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_content {
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_section i {
        transform: rotate(-180deg);
    }

    /* --- */
    .block_list .section_header{
        justify-content: flex-start;
        row-gap: 15px;
    }
    .section_content .section_header .status {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }

    /* .section_content .section_header .switch_option {
        flex: 1 1 auto;
    } */
    .section_content .section_header .handle-cat-drag{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .section_content .section_header .handle-cat-drag i{
        margin: 3px 10px 0 0;
        cursor: move;
    }
    /* .section_content .section_header h3.sub_header{
        flex-wrap: wrap;
    } */
    .section_header .handle-cat-drag span {
        font-size: 13px;
        line-height: 17px;
        color: #f2a31d;
        display: block;
        padding-top: 7px;
    }

    .section_header a.cat_info {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        display: block;
        padding: 0 7px;
        position: relative;
        align-self: center;
    }

    .section_header a.cat_info .quick_info {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 200px;
        min-width: 100px;
        text-align: center;
        position: absolute;
        left: 0;
        top: auto;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }

    .section_header a.cat_info .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    .section_header a.cat_info:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .btn_list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }

    .btn_list li {
        background: #fff;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 3px 10px;
        cursor: pointer;
    }

    @media(max-width: 1499px) {
        .time_selection .form_grp {
            flex: 0 0 100%;
            width: 100%;
        }

        .selection_box {
            font-size: 11px;
            line-height: 15px;
        }

        .time_listing li {
            padding: 3px;
        }

        .tab_sec li {
            font-size: 12px;
            line-height: 15px;
            padding: 8px 12px;
        }
    }

    :deep(.weekdays .multiselect-single-label-text) {
        text-transform: capitalize;
    }

    :deep(.question_list) {
        display: block;
        margin: 0;
    }

    :deep(.question_list li) {
        width: 100%;
    }

    :deep(.question_list li h5 label) {
        margin-right: auto;
        margin-left: 0;
        padding-left: 0;
    }

    .grp_wpr.grp_option {
        display: flex;
        flex-wrap: wrap;
    }

    .box_item {
        flex: 1 0 calc(50% - 10px);
    }

    .box_item label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 5px 7px;
        display: flex;
        align-items: center;
    }

    .mrl-5 {
        margin: 0 -5px;
    }

    .p-10-15 {
        padding: 10px 15px !important;
    }

    :deep(.dp__input_wrap input) {
        font-size: 12px;
        color: #5a5a5a;
    }

    .tab_slider {
        margin: 0 -20px;
    }

    :deep(.dp__main .dp__input) {
        font-size: 11px;
        max-width: 120px;
        padding: 0 0 0 35px !important;
    }

    .addblock_section {
        position: relative;
        width: 100%;
        padding: 10px 10px 0 10px;
        display: flex;
        justify-content: center;
    }
    .addblock_section .add_btn{
        padding: 6px 15px;
        background: #fff;
        border-radius: 15px;
        font-size: 13px;
    }
    .addblock_section .add_btn i {
        font-size: 10px !important;
    }
    .addblock_section .add_types {
        max-width: 572px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 15px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 4;
    }

    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .addblock_section .add_types ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 30px 20px;
    }

    .addblock_section .add_types ul li {
        flex: 0 1 80px;
    }

    .addblock_section .add_types ul li .card_item {
        padding: 15px 5px;
    }

    .addblock_section .add_types .title {
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }

    .block_list .block_actions {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-left: auto;
    }

    .block_list .block_actions button {
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }

    .block_list .block_actions button.up_down {
        color: #999;
    }

    .block_list .block_actions .block_setting {
        position: relative;
        cursor: pointer;
    }

    .block_list .block_actions .block_setting i {
        font-size: 15px;
        color: #999;
    }

    .block_list .block_actions .block_setting .dropdown_wpr {
        min-width: 250px;
        max-height: 350px;
        overflow-y: scroll;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }

    .block_list .block_actions .block_setting .dropdown_wpr::-webkit-scrollbar {
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr:before {
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .sec_label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding: 3px 0;
        display: block;
    }

    .block_list .block_actions .block_setting .dropdown_wpr ul li {
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr ul li .switch_option h5 {
        font-weight: 400;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li .sub_control{
        background: #fff;
        padding: 10px;
        border-radius: 5px;
        margin: -5px 0 20px 0;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li .sub_control .switch_option h5{
        font-size: 11px;
        line-height: 14px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li .sub_control .capsule_btn div{
        flex: 0 0 24px;
        height: 14px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li .sub_control .capsule_btn div span{
        height: 8px;
        width: 8px;
    }
    .block_list .block_actions .block_setting .dropdown_wpr ul li .reset_btn{
        padding: 2px 8px;
        background: #2f7eed;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        border-radius: 10px;
        color: #fff;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr,
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect {
        background: #fff;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr input,
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect {
        height: 30px;
        min-height: 30px;
        font-size: 11px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix {
        padding-right: 40px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix .suffix {
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown) {
        min-width: 100%;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li) {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }

    .block_list li .block_panel {
        border: 1px solid transparent;
        background: #fff;
        border-radius: 6px;
        padding: 30px;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
        position: relative;
    }

    .block_list li .block_panel.active {
        border: 1px solid #c8dfff;
        background: #fbfbfb;
    }

    .add_item{
        position: relative;
        cursor: pointer;
    }
    .add_item.minimal{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .add_item button {
        width: 150px;
        border-radius: 6px;
        background: #fff;
        border: 1px solid #f5f5f5;
        padding: 20px 10px;
        min-height: 134px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        font-size: 13px;
        line-height: 18px;
        gap: 10px;
        color: #999;
        pointer-events: none;
        transition: all 0.5s ease-in-out;
    }
    .add_item.minimal button {
        width: 30px;
        height: 30px;
        min-height: auto;
        border-radius: 50%;
        background: #fff;
        padding: 5px;
        font-size: 11px;
    }
    .add_item.minimal button label{
        display: none;
    }
    .add_item .dropdown_wpr{
        width: 200px;
        padding: 10px 0;
        z-index: 2;
    }
    .add_item.minimal .dropdown_wpr{
        left: auto;
        right: 1px;
    }
    .add_item .dropdown_wpr:before{
        display: none;
    }
    .add_item .dropdown_wpr .drop_item{
        padding: 5px 15px;
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
        cursor: pointer;
    }

    .block_library {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
    }

    .block_library ul.list {
        display: flex;
        flex-wrap: wrap;
        margin: 20px -10px;
        width: 100%;
    }

    .block_library ul.list.shake {
        animation: headShake 0.3s linear 0s infinite alternate;
    }

    .block_library ul.list>li {
        padding: 20px 10px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
    }

    .video_wpr {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
    }
    .video_wpr_flip > img,
    .video_wpr_flip > .player_wpr > img {
        transform: scale(-1, 1);
    }

    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }

    .block_library ul li h6 {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
    }

    .block_library ul li .action_items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .block_library ul li:hover .action_items,
    .block_library ul li.selected .action_items,
    .block_library ul.target li .action_items {
        opacity: 1;
    }

    .block_library ul li .action_items label.checkbox span {
        width: 12px;
        flex: 0 0 12px;
        height: 12px;
        border-radius: 2px;
    }

    .block_library ul li .action_items label.checkbox span i {
        font-size: 8px;
    }

    .block_library ul li .action_items a {
        cursor: pointer;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
    }

    .block_library ul li .action_items a i {
        font-size: 9px;
        padding-right: 4px;
    }

    .block_library .library_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .block_library .library_header a {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: pointer;
    }

    .block_library .filter_search {
        /* border: 1px solid #efefef; */
        height: 34px;
        border-radius: 20px;
        padding: 0 34px 0 0;
        position: relative;
        background: #fbfbfb;
        max-width: 400px;
        width: 100%;
    }

    .block_library .filter_search input {
        font-size: 11px;
        line-height: 34px;
        color: #666;
        border: 0;
        width: 100%;
        padding: 0 15px;
        background: transparent;
        box-sizing: border-box;
    }

    .block_library .filter_search .search_btn {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 0;
        font-size: 11px;
        color: #333;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .block_library .filters {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 25px 0 10px 0;
        position: relative;
        z-index: 2;
    }

    .block_library .filters h5 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
    }

    .filters .filter_item,
    .attribute_list {
        position: relative;
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
    }

    .filters .filter_item li,
    .other_filter_items li {
        font-size: 11px;
        line-height: 15px;
    }

    .other_filter_items {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f1;
        border-radius: 50%;
        font-size: 10px;
        position: relative;
        cursor: pointer;
    }

    .attribute_bar {
        padding: 15px 0;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        width: 230px;
        max-height: 100vh;
        overflow-y: overlay;
        position: relative;
        margin-right: -230px;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
    }

    .attribute_bar.show {
        margin-right: 0;
    }

    .attribute_bar::-webkit-scrollbar {
        width: 4px;
    }

    .attribute_bar::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #cacaca;
    }

    .attribute_bar h3 {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        padding: 0 20px 5px 20px;
        display: flex;
        align-items: center;
    }

    .attribute_bar h3 button {
        font-size: 13px;
        color: #999;
        margin-left: auto;
        cursor: pointer;
    }

    .toggle_btn {
        display: flex;
        justify-content: flex-end;
        padding: 6px 20px 12px 20px;
    }

    .toggle_btn a {
        padding: 4px 10px;
        background: #f5f5f5;
        font-size: 10px;
        line-height: 12px;
        color: #121525;
        font-weight: 500;
        display: block;
        border-radius: 10px;
        cursor: pointer;
    }

    .attribute_grp {
        border-top: 1px solid #f5f5f5;
    }

    .attribute_grp h4 {
        padding: 12px 15px;
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .attribute_grp h4 a {
        color: #5a5a5a;
        margin-right: 15px;
        font-size: 11px;
        display: flex;
        align-items: center;
    }

    .attribute_grp h4 a.pinned {
        color: #2f7eed;
    }

    .attribute_grp h4>i {
        margin-left: auto;
        font-size: 11px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }

    .attribute_grp ul {
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .attribute_grp.active ul {
        max-height: 10000px;
    }

    .attribute_grp.active h4>i {
        transform: rotate(-180deg);
    }

    .attribute_bar ul li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 10px 15px;
    }

    .attribute_bar ul li:last-child {
        margin-bottom: 15px;
    }

    .attribute_bar ul li label.checkbox,
    .dropdown_wpr ul li label.checkbox {
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;
    }

    .add_attribute {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 10px 20px;
        background: #2f7eed;
        color: #fff;
        border-radius: 5px;
        margin: auto 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        gap: 7px;
        cursor: pointer;
    }

    .add_attribute i {
        font-size: 9px;
    }

    .filters .attribute_bar {
        position: fixed;
        right: -230px;
        top: 0;
        height: 100vh;
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
    }

    .filters .attribute_bar.show {
        right: 0;
    }

    .search_tags {
        background: #fbfbfb;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-top: 20px;
    }

    .search_tags li {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }

    .search_tags .delete_btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .search_tags li:hover .delete_btn {
        opacity: 1;
    }

    .dropdown_wpr ul li label.checkbox span i {
        margin: 0;
    }

    .attribute_bar ul li label.checkbox span.active i,
    .dropdown_wpr ul li label.checkbox span.active i {
        transform: scale(1);
        opacity: 1;
    }

    .bulk_action {
        border-right: 1px solid #d9d9d9;
        padding-right: 20px;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .bulk_action .delete_btn {
        color: #eb1414;
        cursor: pointer;
    }

    .filters .bulk_action .filter_item,
    .filters .bulk_action .filter_item i {
        color: #2f7eed;
    }

    .filter_item i {
        font-size: 9px;
        padding: 5px;
        vertical-align: top;
    }

    .filters .filter_item i,
    .attribute_list i {
        color: #7a7a7a;
        pointer-events: none;
    }

    .filters .filter_item .dropdown_wpr,
    .attribute_list .dropdown_wpr {
        min-width: 160px;
        overflow-y: hidden;
    }

    .filters .filter_item .dropdown_wpr ul,
    .attribute_list .dropdown_wpr ul {
        max-height: 400px;
        overflow-y: scroll;
    }

    .attribute_list .dropdown_wpr ul {
        max-height: 200px;
        overflow-y: scroll;
    }

    .filters .filter_item .dropdown_wpr ul::-webkit-scrollbar,
    .other_filter_items .dropdown_wpr::-webkit-scrollbar,
    .attribute_list .dropdown_wpr ul::-webkit-scrollbar,
    .attribute_list .dropdown_wpr ul::-webkit-scrollbar {
        display: none;
    }

    .filters .filter_item .dropdown_wpr ul li,
    .other_filter_items .dropdown_wpr ul li,
    .attribute_list .dropdown_wpr ul li {
        border: 0;
    }

    .filters .filter_item .dropdown_wpr ul li.active {
        background: #f5f5f5;
    }

    .dropdown_wpr ul li.dropdown-header {
        background: #e9e9e9;
    }

    .block_list li .block_panel ul {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .block_list li .block_panel ul li {
        padding: 10px 30px 10px 15px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        position: relative;
    }

    .block_list li .block_panel ul li .block_action {
        position: absolute;
        background: #fff;
        border-radius: 20px;
        padding: 5px 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        z-index: 2;
    }

    /*.block_list li.circuit .block_panel ul li .block_action {
        right: 30px;
    }*/

    .block_list li .block_panel ul li .block_action button {
        padding: 3px 5px;
        color: #2f7eed;
        cursor: pointer;
    }
    .block_list li .block_panel ul li .block_action button img{
        max-height: 14px;
        width: auto;
        max-width: none;
        transform: none;
    }
    .block_list li .block_panel ul li .block_action button label{
        padding: 0;
        cursor: pointer;
        filter: grayscale(1);
        transition: all 0.3s ease-in-out;
    }
    .block_list li .block_panel ul li .block_action button.flipped label{
        filter: grayscale(0);
    }

    .block_list li .block_panel ul li .video_wpr:hover .block_action {
        transform: translate(-50%, -50%) scale(1);
    }

    .block_list li .block_panel ul li h6 {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        text-align: left;
        width: 100%;
    }
    .block_list li .block_panel ul li h6 span{
        white-space: nowrap;
    }
    .block_list li .block_panel ul li h6 span:hover{
        color: #2f7eed;
    }
    .block_list li .block_panel ul li h6.short_title{
        padding-right: 20px;
        position: relative;
        flex-wrap: nowrap;
    }
    .block_list li .block_panel ul li h6.short_title .handle-exercise-drag{
        padding-right: 7px;
        cursor: move;
        color: #121525;
    }
    .block_list li .block_panel ul li h6.short_title label{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0 auto
    }
    .block_list li .block_panel ul li h6.short_title .icon{
        position: absolute;
        right: 0;
        top: 0;
        font-size: 14px;
        margin-left: auto;
    }

    .block_list li .block_panel ul li h6 .v_tag {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        background: #999;
        /* border: 1px solid #e9e9e9; */
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 10px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
        border-radius: 50%;
    }
    .block_list li .block_panel ul li h6 .v_tag:hover{
        background: #2f7eed;
        color: #fff;
    }

    .block_list li .block_panel ul li.rest_list {
        width: auto;
    }

    .block_list li.circuit .block_panel ul li {
        padding-right: 30px;
    }

    .block_list li .block_panel ul li .circuit {
        width: 40px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        position: absolute;
        font-size: 15px;
        color: #2f7eed;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        z-index: 2;
        gap: 5px;
        /* visibility: hidden; */
        cursor: pointer;
    }

    .block_list li .block_panel ul li .circuit a {
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        width: 40px;
        text-align: center;
    }

    .block_list li.circuit .block_panel ul li .circuit {
        visibility: visible;
    }

    .block_list li .block_panel ul li .infinity{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #2f7eed;
        position: absolute;
        top: 50%;
        right: -7px;
        transform: translateY(-50%);
        z-index: 2;
    }

    .rest_list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rest_circle {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffc4c4;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        cursor: pointer;
    }

    .rest_circle span {
        font-size: 15px;
        line-height: 20px;
    }

    .edit_rest {
        width: 230px;
        position: absolute;
        top: 100%;
        right: 1px;
        left: auto;
        margin-top: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 4;
    }

    .edit_rest .title {
        padding: 10px 20px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
    }

    .edit_rest .setting_wpr {
        padding: 20px;
    }

    .edit_rest .input_label {
        font-size: 11px;
        line-height: 13px;
    }

    .edit_rest .field_wpr input {
        height: 32px;
        font-size: 11px;
    }

    .edit_rest :deep(.color-picker .field_wpr input) {
        height: 30px;
        padding: 0 10px;
        font-size: 11px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix) {
        padding-left: 30px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix-right) {
        top: 3px;
        bottom: 3px;
        right: 3px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix) {
        width: 30px;
        height: 30px;
    }

    .edit_rest :deep(.color-picker .pickr),
    .edit_rest :deep(.color-picker .pickr .pcr-button) {
        width: 20px;
        height: 20px;
        line-height: 0;
    }

    .edit_rest :deep(.color-picker .sub_header) {
        font-size: 11px;
        line-height: 14px;
    }

    .edit_rest :deep(.color-picker .swatches li) {
        padding: 4px;
    }

    .edit_rest :deep(.color-picker .swatches li span) {
        width: 20px;
        height: 20px;
    }

    .edit_rest :deep(.color-picker .swatches li span i) {
        font-size: 10px;
    }

    .edit_rest .action_wpr {
        padding: 12px 20px;
        border-top: 1px solid #f5f5f5;
        margin: 0;
    }

    .edit_rest .action_wpr .btn {
        min-width: auto;
        height: 30px;
        font-size: 10px;
        line-height: 28px;
        padding: 0 15px;
    }

    .edit_rest .action_wpr .btn.save_btn {
        color: #fff;
    }

    .workout_config .modal_container {
        padding: 0;
        max-width: 700px;
        width: 100%;
    }

    .workout_config .modal_header {
        padding: 20px 30px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
    }

    .workout_config .modal_footer {
        padding: 20px 30px;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        border-radius: 0 0 10px 10px;
    }

    .workout_config .modal_header h2 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        text-align: left;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .workout_config .modal_header button {
        color: #999;
        font-size: 15px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-self: center;
    }

    .workout_config .modal_body {
        width: 100%;
        padding: 20px 30px;
        max-height: 600px;
    }

    .workout_config .edit_content {
        display: flex;
        gap: 30px;
        margin-bottom: 20px;
    }

    .workout_config .edit_content .thumb_section {
        flex: 0 1 600px;
        display: flex;
        gap: 20px;
        background: #fbfbfb;
        border-radius: 5px;
    }

    .workout_config .edit_content .thumb_section .thumb_title {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 15px 0;
        display: flex;
    }

    .workout_config .edit_content .thumb_section .thumb_title .v_tag {
        width: 20px;
        height: 20px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;
        color: #fff;
        margin-left: 10px;
        border-radius: 50%;
    }

    .workout_config .edit_content .thumb_section .exercise_info {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        margin-top: 10px;
    }

    .workout_config .edit_content .video_section {
        flex: 0 1 280px;
    }

    .workout_config .edit_content .setting_wpr {
        flex: 1 0 180px;
    }

    .set_wpr {
        display: flex;
        margin: 0 -7px;
    }

    .set_wpr .single_item {
        flex: 0 1 15%;
        padding: 7px;
    }

    .set_wpr .public_name {
        flex: 0 1 294px;
        padding: 0 7px;
    }

    .set_wpr .public_descriptions {
        max-width: 544px;
        width: 100%;
        padding: 0 7px;
    }

    .set_wpr .item_wpr {
        flex: 0 1 25%;
        padding: 7px;
    }

    .set_wpr .item_wpr:last-of-type {
        margin-left: auto;
    }

    .column-2 {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }

    .column-2 .item {
        flex: 1 0 calc(50% - 7px);
    }

    .set_wpr .input_label {
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .workout_config .fld_label {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 5px;
        display: block;
    }

    .workout_config .field_wpr input,
    .workout_config .form_grp .multiselect {
        height: 40px;
        min-height: 40px;
        font-size: 13px;
    }

    .form_grp.set_wpr .multiselect {
        min-height: 40px;
    }

    .var_list {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 10px 0;
    }

    .var_list .blank_space {
        min-width: 21%;
    }

    .radio_opt {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }

    .radio_opt p {
        font-size: 14px;
        line-height: 19px;
        padding-left: 8px;
    }

    .radio_box {
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 4px;
    }

    .radio_box .dot {
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .radio_opt input[type="radio"]:checked~.radio_box .dot {
        transform: scale(1);
    }

    .var_table {
        padding: 20px 0;
    }

    .var_table table {
        width: 100%;
        background: #fbfbfb;
        border-radius: 6px;
        border-collapse: collapse;
    }

    .var_table table thead {
        border-bottom: 1px solid #efefef;
    }

    .var_table table tbody tr:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
    }

    .var_table table th {
        padding: 12px 18px;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
    }

    .var_table table td {
        padding: 10px 18px 15px 18px;
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 300;
        vertical-align: bottom;
    }

    .var_table table td .row {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .var_table table td input {
        width: 50px;
        font-weight: 300;
        height: 30px;
        border: 1px solid #CBCDD3;
        border-radius: 5px;
        padding-left: 5px;
    }

    .other_content {
        width: 100%;
        padding: 20px 0 0 0;
    }

    .group_title {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .group_title h4 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
        margin-right: auto;
    }

    .group_title h4 span {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        padding-left: 15px;
    }

    .att_tags {
        background: #fbfbfb;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    .att_tags li {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 7px 15px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }

    .att_tags .delete_btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .att_tags li:hover .delete_btn {
        opacity: 1;
    }

    .att_tags li.exercise,
    .search_tags li.exercise {
        border-color: #88d3d9;
    }

    .att_tags li.position,
    .search_tags li.position {
        border-color: #b99191;
    }

    .att_tags li.contraction,
    .search_tags li.contraction {
        border-color: #968bca;
    }

    .att_tags li.muscle,
    .search_tags li.muscle {
        border-color: #76afff;
    }

    .att_tags li.movement,
    .search_tags li.movement {
        border-color: #cb72ff;
    }

    .att_tags li.equipment,
    .search_tags li.equipment {
        border-color: #56e094;
    }

    .att_tags li.skeletal,
    .search_tags li.skeletal {
        border-color: #fb76c8;
    }

    .att_tags li.difficulty,
    .search_tags li.difficulty {
        border-color: #f2a31d;
    }

    .other_content .field_wpr {
        background: #fff;
    }

    .other_content textarea {
        font-size: 13px;
        line-height: 18px;
        resize: vertical;
    }

    .video-overlay {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        cursor: pointer;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        background: rgba(0,0,0,0);
        transition: all 0.3s ease-in-out;
    }
    .block_list li .block_panel ul li .video_wpr:hover .video-overlay {
        background: rgba(0,0,0,0.05);
    }

    .section_container {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 50px;
    }
    .content_panel {
        border-radius: 8px;
        background: #fff;
        position: relative;
        padding: 30px;
    }

    .content_panel.circuit .set_wpr .single_item.sets,
    .content_panel.circuit .set_wpr .item_wpr.rest,
    .content_panel.circuit .form_grp :deep(ul.multiselect-options li#multiselect-option-Superset),
    .content_panel.progression .form_grp :deep(ul.multiselect-options li[aria-label="Using Time"]),
    .content_panel.no_superset .form_grp :deep(ul.multiselect-options li#multiselect-option-Superset),
    .disable {
        pointer-events: none;
        opacity: 0.7;
    }

    /* .global_setting .section_header h3 {
        display: flex;
        align-items: center;
        gap: 10px;
    } */

    .section_header .handle-cat-drag input {
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        width: 200px;
        background: #f0f5ff;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .section_header .handle-cat-drag .save_btn {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .form_grp.small {
        margin: 0;
    }

    .form_grp.small .field_wpr {
        width: 80px;
    }

    .form_grp.small :deep(.multiselect-multiple-label),
    .form_grp.small :deep(.multiselect-placeholder),
    .form_grp.small :deep(.multiselect-single-label) {
        padding: 0 20px 0 5px;
    }

    .form_grp.small :deep(.multiselect) {
        min-height: 30px;
        height: 30px;
    }

    .form_grp.small :deep(.multiselect-single-label span) {
        font-size: 11px;
    }

    .form_grp.small :deep(.multiselect-caret) {
        margin-right: 5px;
    }

    .load_more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        color: #999;
        gap: 10px;
    }

    .smart_blocklist .add_btn {
        font-size: 13px;
        line-height: 16px;
    }

    .smart_blocklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }

    .smart_blocklist>ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .smart_blocklist>ul .block_info {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 7px 15px 20px 15px;
    }

    .smart_blocklist>ul .block_info>li {
        padding: 7px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .smart_blocklist>ul .block_info li h6 {
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }
    .block_actions .block_setting .disable {
        pointer-events: none;
        opacity: 0.7;
    }

    :deep(.cell .dashboard_content) {
        display: block;
    }


    .field_wpr.has_suffix .suffix.smart-button {
        color: #2F7FED
    }
    /* .workout_info{
        background: #fff;
        border-radius: 8px;
        border: 1px solid #e9e9e9;
    } */
    .inline-edit {
        display: flex;
        align-items: center;
    }
    .workout_info .inline-edit h3{
        padding: 0;
        margin: 0 auto 0 0;
        font-size: 19px;
        line-height: 26px;
    }
    .workout_info .inline-edit .rename_fld{
        padding-right: 46px;
        position: relative;
        margin-right: auto;
        max-width: 360px;
        width: 100%;
    }
    .workout_info .inline-edit input {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
        background: #f0f5ff;
        border-radius: 5px;
    }
    .workout_info .description{
        background: #fff;
        padding: 0 15px;
        border-radius: 5px;
        border: 0;
        position: absolute;
        right: 20px;
        top: 100%;
        z-index: 3;
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .workout_info .description .field_wpr{
        background: #fff;
    }
    .workout_info .description.show{
        border: 1px solid #f5f5f5;
        box-shadow: 0 1px 20px rgb(0,0,0,0.15);
        max-height: 500px;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
        display: inline-table;
    }
    .show_hide_btn{
        position: relative;
        display: flex;
        flex-direction: row-reverse;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: pointer;
        /* float: right; */
        padding: 7px;
    }

    .inline-edit button.save_btn {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
        text-wrap: nowrap;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .inline-edit .pp-label {
        padding: 5px 10px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 9px;
        line-height: 10px;
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        margin: 0 15px 0 0;
    }
    .inline-edit .setting_btn{
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }

    .inline-edit form {
        display: flex;
        align-items: center;
    }

    .inline-edit form .grid {
        display: grid;
        position: relative;
    }

    .inline-edit form span.text-danger {
        position: absolute;
        bottom: -20px;
    }

    .section_header .addblock_section {
        display: flex;
        justify-content: flex-end;
    }

    :deep(.block_actions .info) {
        width: 20px;
        height: 20px;
        font-size: 10px;
        color: #5a5a5a;
        position: relative;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e9e9e9;
        background: #fff;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        margin: 0;
    }

    :deep(.block_actions .info) {
        opacity: 1;
    }

    :deep(.block_actions .info .quick_info) {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 200px;
        min-width: 100px;
        text-align: center;
        position: absolute;
        right: -6px;
        top: auto;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
        z-index: 1;
    }

    :deep(.block_actions .info .quick_info:after) {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        right: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    :deep(.block_actions .info:hover .quick_info) {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .rename_fld input {
        background: transparent;
        font-size: 22px;
        line-height: 28px;
        color: #121525;
        font-weight: 400;
    }

    #rename-button {
        border: 1px solid #2f80ed;
        background: #2f80ed;
        border-radius: 4px;
        font-size: 12px;
        padding: 2px 15px;
        color: #fff;
        text-align: center;
        box-sizing: border-box;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    .color_container.p-15 {
        padding: 10px 15px;
    }

    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }

    .modal.secondary.perform-type-confirmation .modal_container {
        width: 30%;
        min-width: 320px;
    }
    .modal.secondary.perform-type-confirmation .modal_header,
    .modal.secondary.perform-type-confirmation .model_body {
        width: 100%;
        padding: 0;
    }
    .modal.secondary.perform-type-confirmation .modal_header h5 {
        text-align: right;
        font-size: 13px;
    }

    .modal.secondary.perform-type-confirmation .switch_option {
        margin-bottom: 0px;
        border-bottom: 0px;
    }
    .modal.secondary.perform-type-confirmation .capsule_elm p.recommended {
        text-align: left;
        font-size: 13px;
        line-height: 16px;
        color: #4CAF50;
        font-weight: 500;
    }

    .section_title{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
    .section_title label{
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #6a6a6a;
        display: block;
        padding: 3px 0;
    }
    .toggle_details{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        margin: 0 15px;
        cursor: pointer;
    }
    .global_setting .color_container .content_details{
        display: none;
    }
    .global_setting .color_container .content_details.show{
        display: block;
    }

    .coming_soon{
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9f9;
        border-radius: 8px;
        margin: 10px 0;
    }
    .block_list li .block_panel ul li .edit_text{
        padding-right: 40px;
        position: relative;
        display: none;
    }

    .block_list li .block_panel ul li.edit_title .edit_text{
        display: block;
    }
    .block_list li .block_panel ul li.edit_title .short_title{
        display: none;
    }
    .block_list li .block_panel ul li .edit_text .save_btn{
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
        text-wrap: nowrap;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .block_list li .block_panel ul li .edit_text input[type=text] {
        font-size: 13px;
        background: transparent;
        height: 26px;
        width: 100%;
        padding: 0 10px;
        background: #f0f5ff;
        border-radius: 5px;
    }
    .block_list li .block_panel ul li .dropdown_wpr,
    .block_list li .block_panel ul li .circuit .dropdown_wpr {
        text-align: left;
        padding: 15px;
        z-index: 5;
    }
    .block_list li .block_panel ul li .circuit .dropdown_wpr {
        min-width: 200px;
        transform: translateX(-50%);
    }
    .block_list li .block_panel ul li .dropdown_wpr:before{
        display: none;
    }
    .block_list li .block_panel ul li .dropdown_wpr .action_wpr .btn{
        height: 30px;
        font-size: 11px;
        margin-left: 10px;
        width: 100%;
    }
    .block_list li .block_panel ul li .scope_item .input_label{
        display: block;
        font-size: 15px;
        line-height: 20px;
        padding-bottom: 15px;
        color: #121525;
    }
    .block_list li .block_panel ul li .scope_item .field_wpr input{
        height: 30px;
    }
    .block_list li .block_panel ul li .scope_item .field_wpr :deep(.multiselect){
        min-height: 30px;
        font-size: 13px;
        line-height: 18px;
        border: 0;
    }
    .block_list li .block_panel ul li .scope_item :deep(.multiselect-multiple-label),
    .block_list li .block_panel ul li .scope_item :deep(.multiselect-placeholder),
    .block_list li .block_panel ul li .scope_item :deep(.multiselect-single-label) {
        padding-right: 30px;
        width: 100%;
    }
    .block_list li .block_panel ul li .scope_item :deep(.multiselect .multiselect-dropdown ul.multiselect-options li) {
        padding: 10px;
        font-size: 13px;
        line-height: 17px;
        margin: 0;
    }
    .block_list li .block_panel ul li .scope_item :deep(.multiselect .multiselect-dropdown::-webkit-scrollbar){
        width: 3px;
    }
    .block_list li .block_panel ul li .scope_item :deep(.multiselect .multiselect-dropdown::-webkit-scrollbar-thumb){
        border-radius: 2px;
        background: #dbdbdb;
    }
    .block_list li .block_panel ul li .scope_item :deep(.multiselect-clear, .multiselect.small_box .multiselect-clear){
        display: none !important;
    }
    .block_list li .block_panel ul li .scope_item .field_wpr.disabled :deep(.multiselect .multiselect-dropdown ul.multiselect-options li){
        pointer-events: none;
    }
    .block_list li .block_panel ul li .dropdown_wpr .fld_label{
        display: block;
        font-size: 11px;
        line-height: 14px;
        width: 100%;
        margin-bottom: 3px;
        color: #5a5a5a;
    }
    .modal.secondary .title{
        font-size: 22px;
        line-height: 30px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }

    .add_exercise_guide{
        position: fixed;
        z-index: 6;
        right: 55px;
        bottom: -150px;
        max-width: 450px;
        padding: 20px 25px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0,0,0,0.1);
        transition: all 0.6s ease-in-out;
    }
    .add_exercise_guide.show{
        bottom: 70px;
    }
    .add_exercise_guide h4{
        font-size: 18px;
        line-height: 30px;
        color: #2f7eed;
        font-weight: 300;
        text-align: left;
        margin-bottom: 15px;
    }
    .add_exercise_guide h4 span{
        font-weight: 500;
    }
    .add_exercise_guide label.checkbox{
        justify-content: flex-start;
        align-items: center;
        font-size: 15px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .add_exercise_guide label.checkbox span{
        margin-right: 10px;
    }

    .dropdown-white-space-block {
        height: 200px;
    }

    .rest-dropdown-container {
        width: 40px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        position: absolute;
        font-size: 15px;
        color: #2f7eed;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        z-index: 2;
        gap: 5px;
        cursor: pointer;
    }

    @media(max-width: 1350px){
        .block_list li .block_panel ul li{
            width: 33.333%;
        }
        .block_list li .block_panel ul li h6.short_info{
            font-size: 9px;
            line-height: 12px;
        }
        .add_exercise_guide{
            max-width: 350px;
            right: 30px;
        }
        .add_exercise_guide h4{
            font-size: 15px;
            line-height: 23px;
            margin-bottom: 12px;
        }
        .add_exercise_guide label.checkbox{
            font-size: 13px;
            line-height: 16px;
        }
    }
    @media(max-width: 1199px) {
        .info_bar {
            margin-bottom: 30px;
        }
        .info_bar .tutorial_btn{
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
            margin: 0;
        }
        .cover_type {
            margin: 15px -25px;
        }
        .block_list li .block_panel ul li{
            width: 50%;
        }
        .section_header a.cat_info{
            width: 100%;
            order: 1;
        }
        .global_setting .tabs_content .section_content:after {
            left: 10px;
            right: 10px;
        }
    }

    @media(max-width: 991px) {
        .prog_edit {
            padding: 20px;
        }
        .block_list li .block_panel ul li{
            width: 33.333%;
        }
    }

    @media(max-width: 767px) {

        .category_bar input,
        .category_bar .quest_title {
            font-size: 13px;
        }
        .block_list li .block_panel ul li{
            width: 50%;
        }
    }

    @media(max-width: 499px) {
        .day_type li .day_wpr h4 {
            font-size: 11px;
        }

        .sqr_input {
            width: 20px;
            height: 20px;
            font-size: 10px;
        }

        :deep(.dp__main .dp__input) {
            font-size: 10px;
            padding: 0 0 0 25px !important;
        }

        :deep(.dp__main .dp__input_icons) {
            width: 12px;
        }

        .day_type li .day_wpr label {
            width: 40px;
            min-width: auto;
            flex-shrink: 0;
        }

        .time_listing li {
            width: 25%;
        }

        .circumference_list li {
            width: 100%;
        }

        .custom_list li {
            padding: 15px;
        }

        .custom_list li .section_wpr {
            padding: 10px 20px;
        }

        .category_bar .capsule_btn {
            padding: 0 10px 0 0;
        }

        .category_bar .add_btn {
            margin: 0 0 0 10px;
            padding-right: 10px;
        }

        .sub_categories {
            padding: 20px 15px;
        }

        .prog_edit {
            padding: 15px;
        }

        .cover_type {
            margin: 15px -15px;
        }
        .block_list li .block_panel ul li{
            width: 100%;
        }
        .workout_info .inline-edit h3{
            font-size: 15px;
            line-height: 22px;
        }
        .global_setting .tabs_content .section_content:after {
            left: 0;
            right: 0;
        }
        .add_exercise_guide{
            right: 12px;
        }
    }
</style>
